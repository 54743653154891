import React, {useCallback, useState, useEffect, useContext} from 'react';
import {Popup, Input, Textarea, toast, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import Empty from "../utils/Empty";
import '../styles/loginDiscount.css';
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function LoginDiscount({locale, Dialog, dialog, registration}) {
    const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [text, setText] = useState('');

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonDiscount = () => {
        if (!email || !firstName || !lastName || !text) {
            toast({ message: locale.loginDiscount.e, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            const message = {
                type: 'rest',
                path: 'coupon',
                action: 'put',
                data: {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    text: text,
                    type: registration
                }
            };
            restCall(message);
        }
    }

    const PutCoupon = useCallback(data => {
        if (data.toast) {
            toast({message: locale.loginDiscount.f, color: 'info', duration: 1000, display: 'bottom'});
            DialogClose();
        }
    }, [locale.loginDiscount.f, DialogClose]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'put' && restResponse.path === 'coupon') {
            PutCoupon(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, PutCoupon, setRestResponse]);

    return (
        <Popup className="cs-login-discount-popup" width={600} closeOnOverlayClick={false} closeOnEsc={false} display="center" scrollLock={false} headerText={locale.loginDiscount.g} buttons={[{text: locale.loginDiscount.d, cssClass: 'cs-login-discount-cancel', handler: () => DialogClose()},{text: locale.loginDiscount.i, cssClass: 'cs-login-discount-save', handler: () => ButtonDiscount()}]} isOpen={dialog.view === 'loginDiscount'} onClose={DialogClose}>
            <div className="cs-login-discount-helper">
                <p className="mbsc-txt-muted">
                    {locale.loginDiscount.j}
                </p>
            </div>
            <div className="cs-login-discount-helper">
                <p className="mbsc-txt-muted">
                    {locale.loginDiscount.k}
                </p>
            </div>
            <Input inputStyle="underline" label={locale.loginDiscount.b} labelStyle="floating" type="text" name="firstName" value={email} onChange={ev => setFirstName(ev.target.value)} />
            <Input inputStyle="underline" label={locale.loginDiscount.c} labelStyle="floating" type="text" name="lastName" value={email} onChange={ev => setLastName(ev.target.value)} />
            <Input inputStyle="underline" label={locale.loginDiscount.a} labelStyle="floating" type="email" name="Email" value={email} onChange={ev => setEmail(ev.target.value)} />
            <Textarea inputStyle="underline" labelStyle="floating" label={locale.loginDiscount.h} value={text} onChange={(ev) => setText(ev.target.value)} />
        </Popup>
    );
}

export default LoginDiscount;

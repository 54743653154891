import React, {useCallback, useEffect, useState, useContext} from 'react';
import {Popup, Select, Textarea, confirm, toast, Input, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import Empty from '../utils/Empty';
import '../styles/loginReferral.css';
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function LoginReferral({locale, Dialog, referral, setReferral, dialog}) {
    const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);
    const [step, setStep] = useState(0);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [school, setSchool] = useState('');
    const [position, setPosition] = useState('');
    const [currentSchool, setCurrentSchool] = useState('');
    const [currentPosition, setCurrentPosition] = useState('');
    const [user, setUser] = useState({});
    const [referee, setReferee] = useState({});
    const [teaching, setTeaching] = useState('');
    const [collaboration, setCollaboration] = useState('');
    const [professionalism, setProfessionalism] = useState('');
    const [personal, setPersonal] = useState('');
    const [leadership, setLeadership] = useState('');
    const [comments, setComments] = useState('');
    const [hireAgain, setHireAgain] = useState('');

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Referral = () => {
        const message = {
            type: 'rest',
            path: 'user-referral',
            action: 'get',
            data: {
                token: referral
            }
        };
        restCall(message);
    }

    const ButtonNext = () => {
        if (step === 0 && (!firstName || !lastName || !school || !position)) {
            toast({message: locale.loginReferral.ad, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (step === 0 && firstName && lastName && school && position) {
            setStep(1);
        } else if (step === 1 && !teaching && !collaboration && !professionalism && !personal) {
            toast({message: locale.loginReferral.ah, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (step === 1 && teaching && collaboration && professionalism && personal) {
            setStep(2);
        } else if (step === 2 && !hireAgain) {
            toast({message: locale.loginReferral.al, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (step === 2 && hireAgain) {
            NextSave();
        }
    }

    const ButtonText = () => {
        if (step === 0) {
            return locale.loginReferral.d;
        } else if (step === 1) {
            return locale.loginReferral.aj;
        } else if (step === 2) {
            return locale.loginReferral.y;
        }
    }

    const ButtonBack = () => {
        if (step === 1) {
           setStep(0);
        } else if (step === 2) {
            setStep(1);
        }
    }

    const NextSave = () => {
        confirm({
            title: locale.loginReferral.y,
            message: locale.loginReferral.z,
            okText: locale.loginReferral.y,
            cancelText: locale.loginReferral.ac,
            callback: (res) => {
                if (res) {
                    const message = {
                        type: 'rest',
                        path: 'user-referral',
                        action: 'update',
                        data: {
                            email: user.email,
                            referral: referee,
                            teaching: teaching,
                            collaboration: collaboration,
                            professionalism: professionalism,
                            personal: personal,
                            leadership: leadership,
                            comments: comments,
                            hireAgain: hireAgain,
                            firstName: firstName,
                            lastName: lastName,
                            school: school,
                            position: position,
                            currentSchool: currentSchool,
                            currentPosition: currentPosition
                        }
                    };
                    restCall(message);
                }
            }
        });
    }

    const UpdateUserReferral = useCallback(data => {
        if (data.toast) {
            toast({message: locale.loginReferral.aa, color: 'info', duration: 1000, display: 'bottom'});
            setReferral('');
            DialogClose();
        }
    }, [locale.loginReferral.aa, DialogClose]);

    const GetUserReferral = useCallback(data => {
        if (data.referral && data.user) {
            setUser(data.user);
            setReferee(data.referral);
            setFirstName(data.referral.firstName);
            setLastName(data.referral.lastName);
        }
        if (data.error) {
            toast({message: locale.loginReferral.af, color: 'danger', duration: 3000, display: 'bottom'});
        }
    }, [locale.loginReferral.af]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'user-referral') {
            GetUserReferral(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetUserReferral, setRestResponse]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'update' && restResponse.path === 'user-referral') {
            UpdateUserReferral(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, UpdateUserReferral, setRestResponse]);

    useEffect(() => {
        toast({message: locale.loginReferral.ae, color: 'info', duration: 1000, display: 'bottom'});
        Referral();
    }, []);

    return (
        <Popup className="cs-login-referral-popup" width={600} maxHeight={800} closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} display="center" headerText={user.firstName + ' ' + user.lastName + ' | ' +  user.email } buttons={step === 0 ? [{text: ButtonText(), cssClass: 'cs-login-referral-save', handler: () => ButtonNext()}] : [{text: locale.loginReferral.ak, cssClass: 'cs-login-referral-cancel', handler: () => ButtonBack()},{text: ButtonText(), cssClass: 'cs-login-referral-save', handler: () => ButtonNext()}]} isOpen={dialog.view === 'loginReferral'} onClose={DialogClose}>
            {step === 0 &&
                <>
                    <div className="cs-login-referral-helper">
                        <p className="mbsc-txt-muted mbsc-bold">{locale.loginReferral.ab}</p>
                    </div>
                    <Input inputStyle="underline" label={locale.loginReferral.a} labelStyle="floating" type="text" name="firstName" value={firstName} onChange={ev => setFirstName(ev.target.value)} />
                    <Input inputStyle="underline" label={locale.loginReferral.b} labelStyle="floating" type="text" name="lastName" value={lastName} onChange={ev => setLastName(ev.target.value)} />
                    <div className="cs-login-referral-helper">
                        <p className="mbsc-txt-muted">{locale.loginReferral.e}</p>
                    </div>
                    <Input inputStyle="underline" label={locale.loginReferral.c} labelStyle="floating" type="text" name="school" value={school} onChange={ev => setSchool(ev.target.value)} />
                    <div className="cs-login-referral-helper">
                        <p className="mbsc-txt-muted">{locale.loginReferral.g}</p>
                    </div>
                    <Input inputStyle="underline" label={locale.loginReferral.f} labelStyle="floating" type="text" name="position" value={position} onChange={ev => setPosition(ev.target.value)} />
                    <div className="cs-login-referral-helper">
                        <p className="mbsc-txt-muted">{locale.loginReferral.v}</p>
                    </div>
                    <Input inputStyle="underline" label={locale.loginReferral.t} labelStyle="floating" type="text" name="currentSchool" value={currentSchool} onChange={ev => setCurrentSchool(ev.target.value)} />
                    <div className="cs-login-referral-helper">
                        <p className="mbsc-txt-muted">{locale.loginReferral.w}</p>
                    </div>
                    <Input inputStyle="underline" label={locale.loginReferral.u} labelStyle="floating" type="text" name="currentPosition" value={currentPosition} onChange={ev => setCurrentPosition(ev.target.value)} />
                </>
            }
            {step === 1 &&
                <>
                    <div className="cs-login-referral-helper">
                        <p className="mbsc-txt-muted">{locale.loginReferral.i}</p>
                    </div>
                    <Select
                        animation="slide-down"
                        dropdown={false}
                        inputStyle="underline"
                        label={locale.loginReferral.h}
                        labelStyle="floating"
                        rows={locale.loginReferral.ag.length}
                        display="anchored"
                        touchUi={false}
                        value={teaching}
                        data={locale.loginReferral.ag}
                        onChange={args => setTeaching(args.value)}
                    />
                    <div className="cs-login-referral-helper">
                        <p className="mbsc-txt-muted">{locale.loginReferral.k}</p>
                    </div>
                    <Select
                        animation="slide-down"
                        dropdown={false}
                        inputStyle="underline"
                        label={locale.loginReferral.j}
                        labelStyle="floating"
                        rows={locale.loginReferral.ag.length}
                        display="anchored"
                        touchUi={false}
                        value={collaboration}
                        data={locale.loginReferral.ag}
                        onChange={args => setCollaboration(args.value)}
                    />
                    <div className="cs-login-referral-helper">
                        <p className="mbsc-txt-muted">{locale.loginReferral.m}</p>
                    </div>
                    <Select
                        animation="slide-down"
                        dropdown={false}
                        inputStyle="underline"
                        label={locale.loginReferral.l}
                        labelStyle="floating"
                        rows={locale.loginReferral.ag.length}
                        display="anchored"
                        touchUi={false}
                        value={professionalism}
                        data={locale.loginReferral.ag}
                        onChange={args => setProfessionalism(args.value)}
                    />
                    <div className="cs-login-referral-helper">
                        <p className="mbsc-txt-muted">{locale.loginReferral.o}</p>
                    </div>
                    <Select
                        animation="slide-down"
                        dropdown={false}
                        inputStyle="underline"
                        label={locale.loginReferral.n}
                        labelStyle="floating"
                        rows={locale.loginReferral.ag.length}
                        display="anchored"
                        touchUi={false}
                        value={personal}
                        data={locale.loginReferral.ag}
                        onChange={args => setPersonal(args.value)}
                    />
                    <div className="cs-login-referral-helper">
                        <p className="mbsc-txt-muted">{locale.loginReferral.q}</p>
                    </div>
                    <Select
                        animation="slide-down"
                        dropdown={false}
                        inputStyle="underline"
                        label={locale.loginReferral.p}
                        labelStyle="floating"
                        rows={locale.loginReferral.ag.length}
                        display="anchored"
                        touchUi={false}
                        value={leadership}
                        data={locale.loginReferral.ag}
                        onChange={args => setLeadership(args.value)}
                    />
                </>
            }
            {step === 2 &&
                <>
                    <div className="cs-login-referral-helper">
                        <p className="mbsc-txt-muted mbsc-bold">{locale.loginReferral.am}</p>
                    </div>
                    <Select
                        animation="slide-down"
                        dropdown={false}
                        inputStyle="underline"
                        label={locale.loginReferral.x}
                        labelStyle="floating"
                        rows={locale.loginReferral.ai.length}
                        display="anchored"
                        touchUi={false}
                        value={hireAgain}
                        data={locale.loginReferral.ai}
                        onChange={args => setHireAgain(args.value)}
                    />
                    <div className="cs-login-referral-helper">
                        <p className="mbsc-txt-muted mbsc-bold">{locale.loginReferral.r}</p>
                    </div>
                    <div className="cs-login-referral-textarea">
                        <Textarea inputStyle="underline" label={locale.loginReferral.s} labelStyle="floating" value={comments} onChange={(ev) => setComments(ev.target.value)} />
                    </div>
                </>
            }
        </Popup>
    );
}

export default LoginReferral;

import React, {useCallback, useEffect, useState, useContext} from 'react';
import {Input, Button, toast, alert, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import Empty from './utils/Empty';
import './styles/login.css';
import LoginReferral from './dialogs/LoginReferral';
import LoginReset from './dialogs/LoginReset';
import LoginRenew from './dialogs/LoginRenew';
import LoginRegister from './dialogs/LoginRegister';
import LoginDiscount from './dialogs/LoginDiscount';
import LoginHelp from './dialogs/LoginHelp';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLanguage} from "@fortawesome/pro-duotone-svg-icons";
import English from './locale/english.json';
import Spanish from './locale/spanish.json';
import {NetworkContext} from "./utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function Login({width, wss, locale, dialog, Dialog, referral, setReferral, registration, setRegistration, token, reset, setReset, setConfiguration, setNavigation, setDialog, Navigation, setLocale}) {
    const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [renew, setRenew] = useState({});

    const Configuration = () => {
        const message = {
            type: 'rest',
            path: 'account-configuration',
            action: 'get',
            data: reset ? {reset: reset} : {}
        };
        restCall(message);
    }

    const ClickRegistration = () => {
        setRegistration('candidate');
        Dialog({view: 'loginRegister', tab: 'one'});
    }

    const ButtonForgot = () => {
        if (!email) {
            toast({message: locale.login.h, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            const message = {
                type: 'rest',
                path: 'user-reset',
                action: 'update',
                data: {
                    email: email
                }
            };
            restCall(message);
        }
    }

    const ButtonLogin = () => {
        const message = {
            type: 'rest',
            path: 'token',
            action: 'get',
            data: {
                email: email,
                password: password
            }
        };
        restCall(message);
    }

    const ButtonHelp = () => {
        Dialog({view: 'loginHelp'});
    }

    const ButtonLocale = data => {
        if (data === 'en') {
            setLocale(English);
            window.localStorage.locale = 'en';
        } else if (data === 'es') {
            setLocale(Spanish);
            window.localStorage.locale = 'es';
        }
    };

    const UpdateUserReset = useCallback(data => {
        if (data.error) {
            toast({message: locale.login.f, color: 'danger', duration: 3000, display: 'bottom'});
        }
        if (data.toast) {
            toast({message: locale.login.g, color: 'info', duration: 1000, display: 'bottom'});
        }
    }, [locale.login.f, locale.login.g]);

    const GetToken = useCallback(data => {
        if (data.token) {
            window.localStorage.token = data.token;
            token.current = data.token;
        }
        if (data.url) {
            wss.current = data.url;
            Navigation({view: 'dashboard'});
        }
        if (data.renew && data.renew === 'owner') {
            alert({
                title: locale.login.l,
                message: locale.login.m,
                okText: locale.login.i,
                callback: () => {

                }
            });
        }
        if (data.renew && (data.renew === 'migrate' || data.renew === 'udpate')) {
            setRenew(data);
            Dialog({view: 'loginRenew'});
        }
        if (data.error && !reset) {
            toast({message: locale.login.o, color: 'danger', duration: 3000, display: 'bottom'});
        }
        if (data.error && reset) {
            toast({message: locale.login.n, color: 'danger', duration: 3000, display: 'bottom'});
        }
        if (dialog.view === 'loginReset') {
            setReset('');
            Dialog({view: 'close'})
        }
    }, [Dialog, dialog.view, locale.login.n, locale.login.o, locale.login.l, locale.login.m, locale.login.i, token, reset, Navigation]);

    const GetAccountConfiguration = useCallback(data => {
        if (data.configuration) {
            let item = {...data.configuration};
            item.locale.es = Object.assign(item.locale.es, Spanish);
            item.locale.en = Object.assign(item.locale.en, English);
            setConfiguration(item);
            if (window.localStorage.locale && window.localStorage.locale === 'en') {
                setLocale(item.locale.en);
            } else if (window.localStorage.locale && window.localStorage.locale === 'es') {
                setLocale(item.locale.es);
            }
        }
        if (data.url) {
            wss.current = data.url;
        }
        if (data.navigation && data.url) {
            data.navigation.view = 'dashboard';
            setNavigation(data.navigation);
        } else if (data.navigation && !data.url) {
            setNavigation(data.navigation);
        }
        if (data.dialog && registration) {
            data.dialog.view = 'loginRegister';
            data.dialog.tab = 'one';
            setDialog(data.dialog);
        } else if (data.dialog && referral) {
            data.dialog.view = 'loginReferral';
            data.dialog.tab = 'scoring';
            setDialog(data.dialog);
        } else if (data.dialog && reset) {
            data.dialog.view = 'loginReset';
            setDialog(data.dialog);
        } else if (data.dialog) {
            setDialog(data.dialog);
        }
    }, [referral, registration, reset]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'account-configuration') {
            GetAccountConfiguration(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetAccountConfiguration, setRestResponse]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'update' && restResponse.path === 'user-reset') {
            UpdateUserReset(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, UpdateUserReset, setRestResponse]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'token') {
            GetToken(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetToken, setRestResponse]);

    useEffect(() => {
        Configuration();
    }, []);

    return (
        <div className={width > 768 ? "mbsc-grid cs-login-grid" : "mbsc-grid cs-login-grid-small"}>
            {width > 768 &&
                <div className="mbsc-row cs-login-main">
                    <div className="mbsc-col cs-login-block">
                        <div className="cs-login-logo" />
                    </div>
                </div>
            }
            {width > 768 &&
                <div className="mbsc-row cs-login-main">
                    <div className="mbsc-col cs-login-block">
                        <div className="cs-login-image" />
                    </div>
                    <div className="mbsc-col cs-login-block">
                        <div className="cs-login-title mbsc-align-center">
                            <h2>
                                {locale.login.a}
                            </h2>
                        </div>
                        <div className="mbsc-align-center">
                            <p>
                                <span className="mbsc-txt-muted">{locale.login.k}</span> <a className="cs-login-card-links" href="#" onClick={ClickRegistration}>{locale.login.j}</a>
                            </p>
                        </div>
                        <Input inputStyle="outline" label={locale.login.b} labelStyle="floating" type="email" name="Email" value={email} onChange={ev => setEmail(ev.target.value)} />
                        <Input inputStyle="outline" label={locale.login.c} labelStyle="floating" minLength="8" type="password" name="Password" passwordToggle={true} value={password} onChange={ev => setPassword(ev.target.value)} />
                        <div className="mbsc-row">
                            <div className="mbsc-col-4 mbsc-button-group-block cs-login-col-forgot">
                                <Button variant="flat" className="cs-login-button-secondary mbsc-bold cs-login-button-forgot" onClick={() => ButtonForgot()} >
                                    {locale.login.d}
                                </Button>
                            </div>
                        </div>
                        <div className="mbsc-row mbsc-justify-content-end">
                            <div className="mbsc-col-4 mbsc-button-group-block">
                                <Button color="primary" variant="flat" className="cs-login-button mbsc-bold" onClick={() => ButtonLogin()} >
                                    {locale.login.e}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {width < 768 &&
                <div className="mbsc-row cs-login-main">
                    <div className="mbsc-col cs-login-block">
                        <div className="cs-login-logo" />
                        <div className="cs-login-title mbsc-align-center">
                            <h2>
                                {locale.login.a}
                            </h2>
                        </div>
                        <div className="mbsc-align-center">
                            <p>
                                <span className="mbsc-txt-muted">{locale.login.k}</span> <a className="cs-login-card-links" href="#" onClick={ClickRegistration}>{locale.login.j}</a>
                            </p>
                        </div>
                        <Input inputStyle="outline" label={locale.login.b} labelStyle="floating" type="email" name="Email" value={email} onChange={ev => setEmail(ev.target.value)} />
                        <Input inputStyle="outline" label={locale.login.c} labelStyle="floating" minLength="8" type="password" name="Password" passwordToggle={true} value={password} onChange={ev => setPassword(ev.target.value)} />
                        <div className="mbsc-row">
                            <div className="mbsc-col-4 mbsc-button-group-block cs-login-col-forgot">
                                <Button variant="flat" className="cs-login-button-secondary mbsc-bold cs-login-button-forgot" onClick={() => ButtonForgot()} >
                                    {locale.login.d}
                                </Button>
                            </div>
                        </div>
                        <div className="mbsc-row mbsc-justify-content-end">
                            <div className="mbsc-col-4 mbsc-button-group-block">
                                <Button color="primary" variant="flat" className="cs-login-button mbsc-bold" onClick={() => ButtonLogin()} >
                                    {locale.login.e}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="mbsc-row cs-login-main">
                <div className="mbsc-col cs-login-block">
                    <div className="mbsc-row mbsc-justify-content-between">
                        <Button variant="flat" className="cs-login-button-secondary mbsc-bold" onClick={() => {window.localStorage.locale === 'en' ? ButtonLocale('es') : ButtonLocale('en')}} >
                            <FontAwesomeIcon className="cs-login-button-secondary-icon" icon={faLanguage} />{window.localStorage.locale === 'en' ? locale.login.t : locale.login.s}
                        </Button>
                        <Button variant="flat" className="cs-login-button-secondary mbsc-bold" onClick={() => ButtonHelp()} >
                            {locale.login.r}
                        </Button>
                    </div>
                </div>
            </div>
            {dialog.view === 'loginHelp' &&
                <LoginHelp
                    locale={locale}
                    Dialog={Dialog}
                    dialog={dialog}
                />
            }
            {dialog.view === 'loginDiscount' &&
                <LoginDiscount
                    locale={locale}
                    Dialog={Dialog}
                    dialog={dialog}
                    registration={registration}
                />
            }
            {dialog.view === 'loginRegister' &&
                <LoginRegister
                    locale={locale}
                    Dialog={Dialog}
                    dialog={dialog}
                    registration={registration}
                    setRegistration={setRegistration}
                />
            }
            {dialog.view === 'loginRenew' &&
                <LoginRenew
                    locale={locale}
                    Dialog={Dialog}
                    dialog={dialog}
                    renew={renew}
                    setRenew={setRenew}
                />
            }
            {dialog.view === 'loginReset' &&
                <LoginReset
                    locale={locale}
                    Dialog={Dialog}
                    dialog={dialog}
                    reset={reset}
                />
            }
            {dialog.view === 'loginReferral' &&
                <LoginReferral
                    locale={locale}
                    dialog={dialog}
                    Dialog={Dialog}
                    referral={referral}
                    setReferral={setReferral}
                />
            }
        </div>
    );
}

export default Login;

import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Checkbox, Input, Select, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/userSpouse.css';
import DayJS from "react-dayjs";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function UserSpouse({locale, configuration, width, user, email}) {
    const {wsCall} = useContext(NetworkContext);
    const [firstName, setFirstName] = useState(user.spouse.firstName);
    const [lastName, setLastName] = useState(user.spouse.lastName);
    const [spouseEmail, setSpouseEmail] = useState(user.spouse.email);
    const [teaching, setTeaching] = useState(user.spouse.teaching);
    const [citizenship, setCitizenship] = useState(user.spouse.citizenship);
    const [dependents, setDependents] = useState(user.spouse.citizenship);
    const [gender, setGender] = useState(user.spouse.gender);
    const [toSave, setToSave] = useState(false);

    const InputBlur = useCallback(() => {
        const message = {
            type: 'wss',
            path: 'user',
            action: 'update',
            data: {
                email: email,
                spouse: {
                    firstName : firstName,
                    lastName : lastName,
                    email : spouseEmail,
                    teaching : teaching,
                    citizenship: citizenship,
                    dependents: dependents,
                    gender: gender
                }
            }
        };
        wsCall(message);
    }, [firstName, lastName, spouseEmail, teaching, citizenship, dependents, gender, email])

    useEffect(() => {
        if (toSave) {
            InputBlur();
            setToSave(false);
        }
    }, [toSave, InputBlur]);

    return (
        <>
            <div className="mbsc-row cs-user-job-main">
                <div className="mbsc-col">
                    <Input inputStyle="outline" label={locale.userSpouse.l} labelStyle="floating" type="text" name="firstName" value={firstName} onBlur={InputBlur} onChange={ev => setFirstName(ev.target.value)} />
                    <Input inputStyle="outline" label={locale.userSpouse.m} labelStyle="floating" type="text" name="lastName" value={lastName} onBlur={InputBlur} onChange={ev => setLastName(ev.target.value)} />
                    <Select
                        animation="slide-down"
                        dropdown={false}
                        inputStyle="outline"
                        label={locale.userSpouse.e}
                        labelStyle="floating"
                        rows={locale.userSpouse.p.length}
                        display="anchored"
                        touchUi={false}
                        value={gender}
                        data={locale.userSpouse.p}
                        onChange={args => {setGender(args.value); setToSave(true);}}
                    />
                    <Select
                        animation="slide-down"
                        dropdown={false}
                        inputStyle="outline"
                        label={locale.userSpouse.d}
                        labelStyle="floating"
                        rows={10}
                        display="anchored"
                        touchUi={false}
                        value={citizenship}
                        data={configuration.mobi.countries}
                        onChange={args => {setCitizenship(args.value); setToSave(true);}}
                    />
                    <Checkbox
                        cssClass="cs-user-spouse-checkbox mbsc-bold"
                        color="info"
                        position="start"
                        label={locale.userSpouse.a}
                        description={locale.userSpouse.b}
                        checked={teaching}
                        onChange={(ev) => {setTeaching(ev.target.checked); setToSave(true);}}
                    />
                    {teaching &&
                        <Input inputStyle="outline" label={locale.userSpouse.k} labelStyle="floating" type="email" name="spouseEmail" value={spouseEmail} onBlur={InputBlur} onChange={ev => setSpouseEmail(ev.target.value)} />
                    }
                    <Input inputStyle="outline" label={locale.userSpouse.n} labelStyle="floating" type="text" name="dependents" value={dependents} onBlur={InputBlur} onChange={ev => setDependents(ev.target.value)} />
                </div>
            </div>
            {user.lastLogin && width > 992 &&
                <div className="mbsc-row cs-user-spouse-footer">
                    <div className="mbsc-col cs-user-spouse-footer-actions" />
                    <div className="mbsc-col-auto cs-user-spouse-footer-note">
                        <div className="mbsc-txt-muted mbsc-italic">{locale.userSpouse.c} <DayJS locale={user.locale} format="MMMM D, YYYY h:mm A">{user.lastLogin}</DayJS></div>
                    </div>
                </div>
            }
        </>
    );
}

export default UserSpouse;

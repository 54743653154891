import React, {useState, useContext, useRef, useCallback, useEffect} from 'react';
import {Button, confirm, setOptions, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImages} from "@fortawesome/pro-duotone-svg-icons";
import '../styles/schoolGallery.css';
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";
import SchoolImage from "../dialogs/SchoolImage";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function SchoolGallery({locale, Dialog, dialog, account}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const hiddenFileInput = useRef(null);
    const [image, setImage] = useState('');

    const ClickDelete = data => {
        confirm({
            title: locale.schoolGallery.d,
            message: locale.schoolGallery.e,
            okText: locale.schoolGallery.b,
            cancelText: locale.schoolGallery.a,
            callback: (res) => {
                if (res) {
                    const message = {
                        type: 'wss',
                        path: 'account-image',
                        action: 'delete',
                        data: {
                            item: data,
                            gallery: account.gallery,
                            school: account.id
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    const ButtonUpload = () => {
        hiddenFileInput.current.click();
    }

    const ClickImage = data => {
        setImage(data.link);
        Dialog({view: 'schoolsSchoolImage'});
    }

    const InputFile = data => {
        const name = data.target.files[0].name;
        let file = '';

        const reader = new FileReader();
        reader.addEventListener('load', ()=>{
            file = reader.result;

            const message = {
                type: 'wss',
                path: 'account-image',
                action: 'put',
                data: {
                    school: account.id,
                    file: file,
                    name: name,
                    gallery: account.gallery
                }
            };
            wsCall(message);
        })
        reader.readAsDataURL(data.target.files[0]);
    }

    const School = useCallback(data => {
        const message = {
            type: 'wss',
            path: 'school',
            action: 'get',
            data: {
                id: data
            }
        };
        wsCall(message);
    }, [wsCall]);

    const PutAccountImage = useCallback(data => {
        if (data.gallery) {
            School(account.id);
        }
        if (data.toast) {
            toast({message: locale.schoolGallery.g, color: 'info', duration: 1000, display: 'bottom'});
        }
    }, [locale.schoolGallery.g, School, account.id]);

    const DeleteAccountImage = useCallback(data => {
        if (data.toast) {
            School(account.id);
            toast({message: locale.schoolGallery.g, color: 'info', duration: 1000, display: 'bottom'});
        }
    }, [locale.schoolGallery.g, School, account.id]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'account-image') {
            DeleteAccountImage(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeleteAccountImage, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'account-image') {
            PutAccountImage(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutAccountImage, setWsResponse]);

    return (
        <>
            <div className="mbsc-row cs-school-gallery-section">
                <div className="mbsc-col">
                    {!Empty(account.gallery) && account.gallery.col1.length !== 0 && account.gallery.col1.map((item) => {
                        return (
                            <>
                                <div className="cs-school-gallery-card" onClick={(ev) => {ClickImage(item)}}>
                                    <img src={item.link} alt={item.link} />
                                </div>
                                <span className="mbsc-desc mbsc-bold cs-school-gallery-edit-field-right">
                                        <a href="#" onClick={(ev) => ClickDelete(item)}>
                                            {locale.schoolGallery.d}
                                        </a>
                                </span>
                            </>
                        );
                    })}
                </div>
                <div className="mbsc-col">
                    {!Empty(account.gallery) && account.gallery.col2.length !== 0 && account.gallery.col2.map((item) => {
                        return (
                            <>
                                <div className="cs-school-gallery-card" onClick={(ev) => {ClickImage(item)}}>
                                    <img src={item.link} alt={item.link} />
                                </div>
                                <span className="mbsc-desc mbsc-bold cs-school-gallery-edit-field-right">
                                        <a href="#" onClick={(ev) => ClickDelete(item)}>
                                            {locale.schoolGallery.d}
                                        </a>
                                </span>
                            </>
                        );
                    })}
                </div>
                <div className="mbsc-col">
                    {!Empty(account.gallery) && account.gallery.col3.length !== 0 && account.gallery.col3.map((item) => {
                        return (
                            <>
                                <div className="cs-school-gallery-card" onClick={(ev) => {ClickImage(item)}}>
                                    <img src={item.link} alt={item.link} />
                                </div>
                                <span className="mbsc-desc mbsc-bold cs-school-gallery-edit-field-right">
                                        <a href="#" onClick={(ev) => ClickDelete(item)}>
                                            {locale.schoolGallery.d}
                                        </a>
                                </span>
                            </>
                        );
                    })}
                </div>
                <div className="mbsc-col">
                    {!Empty(account.gallery) && account.gallery.col4.length !== 0 && account.gallery.col4.map((item) => {
                        return (
                            <>
                                <div className="cs-school-gallery-card" onClick={(ev) => {ClickImage(item)}}>
                                    <img src={item.link} alt={item.link} />
                                </div>
                                <span className="mbsc-desc mbsc-bold cs-school-gallery-edit-field-right">
                                        <a href="#" onClick={(ev) => ClickDelete(item)}>
                                            {locale.schoolGallery.d}
                                        </a>
                                </span>
                            </>
                        );
                    })}
                </div>
            </div>
            <div className="mbsc-row cs-school-gallery-footer">
                <div className="mbsc-col cs-school-gallery-footer-actions">
                    <div className="mbsc-row">
                        <Button color="primary" variant="flat" className="mbsc-bold" onClick={() => ButtonUpload()} >
                            <FontAwesomeIcon className="cs-school-gallery-information-icon-item" icon={faImages} /> {locale.schoolGallery.c}
                        </Button>
                    </div>
                </div>
                <div className="mbsc-col-auto cs-school-gallery-footer-note">
                    <div className="mbsc-txt-muted mbsc-italic">{locale.schoolGallery.f}</div>
                </div>
            </div>
            {dialog.view === "schoolImage" &&
                <SchoolImage
                    Dialog={Dialog}
                    dialog={dialog}
                    image={image}
                />
            }
            <div style={{display: 'none'}}>
                <input type="file" ref={hiddenFileInput} onChange={InputFile} />
            </div>
        </>
    );
}

export default SchoolGallery;

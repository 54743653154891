import React, {useState, useContext, useCallback, useEffect} from 'react';
import {setOptions, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/jobOverview.css';
import Empty from '../utils/Empty';
import {NetworkContext} from "../utils/NetworkContext";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function JobOverview({locale, job, setJob}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [overview, setOverview] = useState(job.overview);
    const [draft, setDraft] = useState(true);

    const EditorUpdate = data => {
        setOverview(data);

        if (data && data.length % 10 === 0) {
            const item = {...job};
            item.overview = data;
            setJob(item);

            const message = {
                type: 'wss',
                path: 'job',
                action: 'update',
                data: {
                    id: job.id,
                    overview: data
                }
            };
            wsCall(message);
        }
    }

    const EditorBlur = () => {
        const item = {...job};
        item.overview = overview;
        setJob(item);

        const message = {
            type: 'wss',
            path: 'job',
            action: 'update',
            data: {
                id: job.id,
                overview: overview
            }
        };
        wsCall(message);
    }

    const UpdateJob = useCallback(data => {
        if (data.toast && !draft) {
            setDraft(true);
            toast({message: locale.jobOverview.d, color: 'info', duration: 1000, display: 'bottom'});
        }
    }, [locale.jobOverview.d, draft]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'job') {
            UpdateJob(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateJob, setWsResponse]);

    return (
        <>
            <div className="cs-job-overview-col-ck">
                <CKEditor
                    id="editor-focus"
                    config={{toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'undo', 'redo']}}
                    editor={ClassicEditor}
                    data={overview}
                    onChange={(event, editor) => EditorUpdate(editor.getData())}
                    onBlur={() => {EditorBlur(); setDraft(false);}}
                />
            </div>
            <div className="mbsc-row cs-job-overview-footer">
                <div className="mbsc-col cs-job-overview-footer-actions" >
                    <div className="mbsc-txt-muted mbsc-italic">{locale.jobOverview.e}</div>
                </div>
                <div className="mbsc-col-auto cs-job-overview-footer-note" />
            </div>
        </>
    );
}

export default JobOverview;

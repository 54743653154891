import React, {useState, useContext, useEffect, useCallback} from 'react';
import {setOptions, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/schoolsFavorites.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMemoPad} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function SchoolsFavorites({locale, setSchool, Navigation, width}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [favorites, setFavorites] = useState([]);

    const Schools = () => {
        const message = {
            type: 'wss',
            path: 'schools',
            action: 'get',
            data: {}
        };
        wsCall(message);
    }

    const ListOpen = data => {
        const message = {
            type: 'wss',
            path: 'school',
            action: 'get',
            data: {
                id: favorites[data].account
            }
        };
        wsCall(message);
    }

    const ListItem = data => {
        return <li>
            <div className="mbsc-row cs-schools-favorites-list">
                {data.item.logo && width > 768 &&
                    <div style={{backgroundImage: "url(" + data.item.logo + ")"}} className="cs-schools-favorites-avatar" />
                }
                {!data.item.logo && width > 768 &&
                    <div style={{backgroundImage: "url('https://www.gravatar.com/avatar/" + (+ new Date()) + "?d=identicon')"}} className="cs-schools-favorites-avatar" />
                }
                <div className="cs-schools-favorites-job">
                    <div className="mbsc-row">
                        <h4 className="cs-schools-favorites-job-text">{data.item.name}</h4>
                        {data.item.text &&
                            <FontAwesomeIcon className="cs-schools-favorites-icon" icon={faMemoPad} />
                        }
                    </div>
                    <div>
                        {data.item.country &&
                            <span dangerouslySetInnerHTML={{__html: data.item.country}} />
                        }
                        {data.item.city &&
                            <span> | </span>
                        }
                        {data.item.city &&
                            <span dangerouslySetInnerHTML={{__html: data.item.city}} />
                        }
                        {data.item.state &&
                            <span> | </span>
                        }
                        {data.item.state &&
                            <span dangerouslySetInnerHTML={{__html: data.item.state}} />
                        }
                    </div>
                </div>
            </div>
        </li>;
    }

    const GetSchools = useCallback(data => {
        if (data.schools) {
            setFavorites(data.schools);
        }
    }, []);

    const GetSchool = useCallback(data => {
        if (data.school) {
            setSchool(data);

            Navigation({tab: 'school', item: 'school', save: true});
        }
    }, [Navigation]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'school') {
            GetSchool(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetSchool, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'schools') {
            GetSchools(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetSchools, setWsResponse]);

    useEffect(() => {
        toast({message: locale.schoolsFavorites.b, color: 'info', duration: 1000, display: 'bottom'});
        Schools();
    }, []);

    return (
        <>
            {favorites.length === 0 &&
                <div className="cs-schools-favorites-helper mbsc-bold mbsc-txt-muted">
                    <p>{locale.schoolsFavorites.a}</p>
                </div>
            }
            <Listview
                theme="ios"
                themeVariant="light"
                itemType={ListItem}
                data={favorites}
                onItemTap={event => ListOpen(event.index)}
            />
        </>
    );
}

export default SchoolsFavorites;

import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Segmented, SegmentedGroup, setOptions, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/candidates.css';
import '../styles/ckeditor.css';
import Empty from "../utils/Empty";
import Menu from "../navigation/Menu";
import Item from "../navigation/Item";
import Notes from "../shared/Notes";
import CandidatesSearch from "../blocks/CandidatesSearch";
import Candidate from "../shared/Candidate";
import CandidateNotes from "../shared/CandidateNotes";
import CandidateNotesShared from "../shared/CandidateNotesShared";
import CandidatesResults from "../blocks/CandidatesResults";
import CandidatesFavorites from "../blocks/CandidatesFavorites";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function Candidates({width, locale, navigation, Navigation, dialog, Dialog, user, configuration}) {
    const {wsResponse, setWsResponse} = useContext(NetworkContext);
    const [text, setText] = useState('');
    const [filter, setFilter] = useState('');
    const [candidate, setCandidate] = useState({});
    const [results, setResults] = useState([]);
    const [result, setResult] = useState({});
    const [draft, setDraft] = useState(true);

    const PutUserNotes = useCallback(data => {
        if (data.toast && !draft) {
            setDraft(true);
            toast({message: locale.candidates.c, color: 'info', duration: 3000, display: 'bottom'});
        }
    }, [locale.candidates.c, draft]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'user-notes') {
            PutUserNotes(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutUserNotes, setWsResponse]);

    useEffect(() => {
        Navigation({menu: locale.candidates.d});
    }, []);

  return (
      <div className="mbsc-grid cs-candidates-main">
          <div className={width > 768 ? "mbsc-row cs-candidates-container" : "mbsc-row cs-candidates-container-xs"}>
              <div className="mbsc-col cs-candidates-title">
                  <p className={width > 768 ? "mbsc-txt-l" : "mbsc-txt-m"}>
                      {locale.candidates.b}
                  </p>
              </div>
          </div>
          <div className="mbsc-row">
              {width > 768 &&
                  <div className="mbsc-col-12 mbsc-col-md-3 mbsc-col-sm-12 cs-candidates-left">
                      {navigation.menu && navigation.menu.length !== 0 &&
                          <Listview
                              theme="ios"
                              themeVariant="light"
                              select="single"
                              itemType={event => Item(event, navigation, null)}
                              data={navigation.menu}
                              onItemTap={event => Menu(event.index, Navigation, navigation)}
                          />
                      }
                      <Notes
                          locale={locale}
                          dialog={dialog}
                          Dialog={Dialog}
                          user={user}
                      />
                  </div>
              }
              <div className="mbsc-col-12 mbsc-col-md-9 mbsc-col-sm-12 cs-candidates-right">
                  {navigation.menu && navigation.menu.length !== 0 && width < 768 &&
                      <div className="mbsc-row cs-candidates-section">
                          <div className="mbsc-col">
                              <SegmentedGroup className="cs-candidates-nav" name="jobs" color="primary" value={navigation.tab} onChange={ev => Menu(ev, Navigation, navigation)}>
                                  {navigation.menu.map((item) => {
                                      return (
                                          <Segmented value={item.name} >
                                              {item.label}
                                          </Segmented>
                                      );
                                  })}
                              </SegmentedGroup>
                          </div>
                      </div>
                  }
                  {navigation.tab === 'search' &&
                      <CandidatesSearch
                          locale={locale}
                          setResults={setResults}
                          user={user}
                          text={text}
                          setText={setText}
                          filter={filter}
                          setFilter={setFilter}
                          results={results}
                      />
                  }
                  {navigation.tab === 'search' && results.length !== 0 &&
                      <CandidatesResults
                          results={results}
                          setResult={setResult}
                          width={width}
                          Navigation={Navigation}
                          setCandidate={setCandidate}
                      />
                  }
                  {navigation.tab === 'candidate' && !Empty(candidate) &&
                      <Candidate
                          locale={locale}
                          candidate={candidate.candidate}
                          candidateData={candidate}
                          configuration={configuration}
                          width={width}
                          Dialog={Dialog}
                          dialog={dialog}
                          navigation={navigation}
                          Navigation={Navigation}
                      />
                  }
                  {navigation.tab === 'candidateNotes' && !Empty(candidate) &&
                      <CandidateNotes
                          locale={locale}
                          candidate={candidate.candidate}
                          candidateData={candidate}
                          setDraft={setDraft}
                      />
                  }
                  {navigation.tab === 'shared' && !Empty(candidate) &&
                      <CandidateNotesShared
                          locale={locale}
                          candidate={candidate.candidate}
                          candidateData={candidate}
                          setDraft={setDraft}
                      />
                  }
                  {navigation.tab === 'favorites' &&
                      <CandidatesFavorites
                          locale={locale}
                          Dialog={Dialog}
                          dialog={dialog}
                          width={width}
                          setCandidate={setCandidate}
                          Navigation={Navigation}
                      />
                  }
              </div>
          </div>
      </div>
  );
}

export default Candidates;

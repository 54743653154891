import React, {useCallback} from 'react';
import {Popup, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/schoolImage.css';

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function SchoolImage({Dialog, dialog, image}) {

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    return (
        <Popup className="cs-school-image-popup" scrollLock={false} display="center" buttons={[]} isOpen={dialog.view === 'schoolImage'} onClose={DialogClose}>
            <div className="cs-school-image-popup-card">
                <img src={image}  alt={image} />
            </div>
        </Popup>
    );
}

export default SchoolImage;

import React, {useCallback, useState, useContext} from 'react';
import {Popup, Button, toast, Input, Select, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/schoolFacultyEthnicity.css';
import Empty from "../utils/Empty";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGrid2Plus, faTrashCan, faEarthAmericas, faEarthAsia, faEarthAfrica, faEarthEurope, faGlobe} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function SchoolFacultyEthnicity({Dialog, dialog, locale, account}) {
    const {wsCall} = useContext(NetworkContext);
    const [facultyEthnicity, setFacultyEthnicity] = useState(account.stats.facultyEthnicity);
    const [ethnicity, setEthnicity] = useState('asianPacificIslander');
    const [count, setCount] = useState('');

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonDelete = data => {
        const item = {...facultyEthnicity};
        let array = item.array;
        array = array.filter(function(item){
            return item.ethnicity !== data.ethnicity;
        })
        item.array = array;
        setFacultyEthnicity(item);
        ItemSave(array);
    }

    const ButtonStats = () => {
        if (!count || !ethnicity) {
            toast({message: locale.schoolFacultyEthnicity.c, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            const item = {...facultyEthnicity};
            let array = item.array;
            if (array.length !== 0) {
                array = array.filter(function(item){
                    return item.ethnicity !== ethnicity;
                })
            }
            array.push({'ethnicity': ethnicity, 'count': count});
            item.array = array;
            setFacultyEthnicity(item);
            setCount('');
            ItemSave(array);
        }
    }

    const ListItem = data => {
        return <li>
            <div className="mbsc-row">
                <div className="mbsc-col">
                    <div className="mbsc-row cs-school-faculty-ethnicity-item-name">
                        {data.item.ethnicity === 'asianPacificIslander' &&
                            <FontAwesomeIcon className="cs-school-faculty-ethnicity-item-icon-left" icon={faEarthAsia} />
                        }
                        {data.item.ethnicity === 'blackAfrican' &&
                            <FontAwesomeIcon className="cs-school-faculty-ethnicity-item-icon-left" icon={faEarthAfrica} />
                        }
                        {data.item.ethnicity === 'hispanicLatinX' &&
                            <FontAwesomeIcon className="cs-school-faculty-ethnicity-item-icon-left" icon={faEarthAmericas} />
                        }
                        {data.item.ethnicity === 'middleEastern' &&
                            <FontAwesomeIcon className="cs-school-faculty-ethnicity-item-icon-left" icon={faEarthAsia} />
                        }
                        {data.item.ethnicity === 'nativeAmericanIndigenous' &&
                            <FontAwesomeIcon className="cs-school-faculty-ethnicity-item-icon-left" icon={faEarthAmericas} />
                        }
                        {data.item.ethnicity === 'whiteCaucasianEuropean' &&
                            <FontAwesomeIcon className="cs-school-faculty-ethnicity-item-icon-left" icon={faEarthEurope} />
                        }
                        {data.item.ethnicity === 'multiracialBiracial' &&
                            <FontAwesomeIcon className="cs-school-faculty-ethnicity-item-icon-left" icon={faGlobe} />
                        }
                        {data.item.ethnicity === 'other' &&
                            <FontAwesomeIcon className="cs-school-faculty-ethnicity-item-icon-left" icon={faGlobe} />
                        }
                        <div className="mbsc-bold mbsc-txt-muted cs-school-faculty-ethnicity-item-text">
                            <span>{data.item.ethnicity === 'asianPacificIslander' && locale.schoolFacultyEthnicity.f}{data.item.ethnicity === 'blackAfrican' && locale.schoolFacultyEthnicity.g}{data.item.ethnicity === 'hispanicLatinX' && locale.schoolFacultyEthnicity.h}{data.item.ethnicity === 'middleEastern' && locale.schoolFacultyEthnicity.i}{data.item.ethnicity === 'nativeAmericanIndigenous' && locale.schoolFacultyEthnicity.j}{data.item.ethnicity === 'whiteCaucasianEuropean' && locale.schoolFacultyEthnicity.k}{data.item.ethnicity === 'multiracialBiracial' && locale.schoolFacultyEthnicity.l}{data.item.ethnicity === 'other' && locale.schoolFacultyEthnicity.m} ({data.item.count})</span>
                        </div>
                    </div>
                </div>
                <div className="mbsc-col-auto cs-school-faculty-ethnicity-item-buttons">
                    <Button color="primary" variant="flat" className="mbsc-bold" onClick={() => ButtonDelete(data.item)} >
                        <FontAwesomeIcon className="cs-school-faculty-ethnicity-icon" icon={faTrashCan} />
                    </Button>
                </div>
            </div>
        </li>;
    }

    const ItemSave = data => {
        const item = {
            leadershipEthnicity: account.stats.leadershipEthnicity.array,
            leadershipGender: account.stats.leadershipGender.array,
            facultyEthnicity: data,
            facultyGender: account.stats.facultyGender.array
        };

        const message = {
            type: 'wss',
            path: 'account',
            action: 'update',
            data: {
                id: account.id,
                stats: item
            }
        };
        wsCall(message);
    }

    return (
        <Popup className="cs-school-faculty-ethnicity-popup" width={600} closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} display="center" headerText={locale.schoolFacultyEthnicity.d} buttons={[{text: locale.schoolFacultyEthnicity.n, cssClass: 'cs-school-faculty-ethnicity-cancel', handler: () => DialogClose()}]} isOpen={dialog.view === 'schoolFacultyEthnicity'} onClose={DialogClose}>
            <Select
                animation="slide-down"
                dropdown={false}
                inputStyle="underline"
                label={locale.schoolFacultyEthnicity.e}
                labelStyle="floating"
                rows={locale.schoolFacultyEthnicity.q.length}
                display="anchored"
                touchUi={false}
                value={ethnicity}
                data={locale.schoolFacultyEthnicity.q}
                onChange={args => setEthnicity(args.value)}
            />
            <Input inputStyle="underline" label={locale.schoolFacultyEthnicity.a} labelStyle="floating" type="number" name="count" value={count} onChange={ev => setCount(ev.target.value)} />
            <Button color="primary" variant="flat" className="mbsc-bold cs-school-faculty-ethnicity-button-add" onClick={() => ButtonStats()} >
                <FontAwesomeIcon className="cs-school-faculty-ethnicity-icon" icon={faGrid2Plus} /> {locale.schoolFacultyEthnicity.p}
            </Button>
            <div className="cs-school-faculty-ethnicity-helper">
                <p className="mbsc-txt-muted">
                    <span className="mbsc-bold">{locale.schoolFacultyEthnicity.b}</span>
                </p>
            </div>
            {!Empty(facultyEthnicity) && facultyEthnicity.array.length !== 0 &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    sortable={true}
                    itemType={ListItem}
                    data={facultyEthnicity.array}
                />
            }
        </Popup>
    );
}

export default SchoolFacultyEthnicity;

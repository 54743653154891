import React, {useCallback, useState, useContext, useEffect} from 'react';
import {Input, Popup, setOptions, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/loginReset.css';
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function LoginReset({locale, Dialog, dialog, reset}) {
    const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Reset = () => {
        const message = {
            type: 'rest',
            path: 'token-user',
            action: 'get',
            data: {
                token: reset
            }
        };
        restCall(message);
    }

    const ButtonSubmit = () => {
        if (!password || !confirm) {
            toast({message: locale.loginReset.e, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (password !== confirm ) {
            toast({message: locale.loginReset.f, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            const message = {
                type: 'rest',
                path: 'token',
                action: 'get',
                data: {
                    email: email,
                    password: password,
                    reset: reset
                }
            };
            restCall(message);
        }
    }

    const GetTokenUser = useCallback(data => {
        if (data.email) {
            setEmail(data.email);
        }
        if (data.error) {
            toast({message: locale.loginReset.h, color: 'danger', duration: 3000, display: 'bottom'});
        }
    }, [locale.loginReset.h]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'token-user') {
            GetTokenUser(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetTokenUser, setRestResponse]);

    useEffect(() => {
        Reset();
    }, []);

    return (
        <Popup className="cs-login-reset-popup" width={600} closeOnOverlayClick={false} closeOnEsc={false} display="center" headerText={email + ' | ' + locale.loginReset.a} buttons={[{text: locale.loginReset.g, cssClass: 'cs-login-reset-save', handler: () => ButtonSubmit()}]} isOpen={dialog.view === 'loginReset'} onClose={DialogClose}>
            <div className="cs-login-reset-helper">
                <p className="mbsc-txt-muted">
                    {locale.loginReset.d}
                </p>
            </div>
            <Input inputStyle="underline" label={locale.loginReset.b} labelStyle="floating" type="password" passwordToggle={true} minLength="8" name="password" value={password} onChange={ev => setPassword(ev.target.value)} />
            <Input inputStyle="underline" label={locale.loginReset.c} labelStyle="floating" type="password" passwordToggle={true} minLength="8" name="confirm" value={confirm} onChange={ev => setConfirm(ev.target.value)} />
        </Popup>
    );
}

export default LoginReset;

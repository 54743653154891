import React, {useCallback} from 'react';
import {Popup, Segmented, SegmentedGroup, Select, Textarea, Input, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/candidateReferral.css';

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function CandidateReferral({locale, Dialog, dialog, referral}) {

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const SegmentedTab = data => {
        Dialog({tab: data.target.value});
    }

    return (
        <Popup headerText={referral.firstName + ' ' + referral.lastName} maxHeight={960} scrollLock={false} display="center" fullScreen={true} buttons={[{text: locale.candidateReferral.y, cssClass: 'cs-candidate-referral-cancel', handler: ()=> DialogClose()}]} isOpen={dialog.view === 'candidateReferral'} onClose={DialogClose}>
            <SegmentedGroup className="cs-candidate-referral-nav" name="candidateReferral" color="primary" value={dialog.tab} onChange={SegmentedTab}>
                <Segmented value="general" >
                    {locale.candidateReferral.a}
                </Segmented>
                {(referral.teaching || referral.collaboration || referral.professionalism || referral.personal || referral.leadership) &&
                <Segmented value="referral" >
                    {locale.candidateReferral.b}
                </Segmented>
                }
                {(referral.hireAgain || referral.comments) &&
                    <Segmented value="comments" >
                        {locale.candidateReferral.c}
                    </Segmented>
                }
            </SegmentedGroup>
            {dialog.tab === 'general' &&
                <>
                    <Input disabled inputStyle="outline" label={locale.candidateReferral.d} labelStyle="floating" type="text" name="firstName" value={referral.firstName} />
                    <Input disabled inputStyle="outline" label={locale.candidateReferral.e} labelStyle="floating" type="text" name="lastName" value={referral.lastName} />
                    {referral.school &&
                        <>
                            <Input disabled inputStyle="outline" label={locale.candidateReferral.f} labelStyle="floating" type="text" name="school" value={referral.school} />
                            <div className="cs-login-referral-input-text">
                                <p className="mbsc-txt-muted mbsc-txt-s mbsc-bold">{locale.candidateReferral.g}</p>
                            </div>
                        </>
                    }
                    {referral.position &&
                        <>
                            <Input disabled inputStyle="outline" label={locale.candidateReferral.ab} labelStyle="floating" type="text" name="position" value={referral.position} />
                            <div className="cs-login-referral-input-text">
                                <p className="mbsc-txt-muted mbsc-txt-s mbsc-bold">{locale.candidateReferral.ac}</p>
                            </div>
                        </>
                    }
                    {referral.currentSchool &&
                        <>
                            <Input disabled inputStyle="outline" label={locale.candidateReferral.ad} labelStyle="floating" type="text" name="currentSchool" value={referral.currentSchool} />
                            <div className="cs-login-referral-input-text">
                                <p className="mbsc-txt-muted mbsc-txt-s mbsc-bold">{locale.candidateReferral.ae}</p>
                            </div>
                        </>
                    }
                    {referral.currentPosition &&
                        <>
                            <Input disabled inputStyle="outline" label={locale.candidateReferral.af} labelStyle="floating" type="text" name="currentPosition" value={referral.currentPosition} />
                            <div className="cs-login-referral-input-text">
                                <p className="mbsc-txt-muted mbsc-txt-s mbsc-bold">{locale.candidateReferral.af}</p>
                            </div>
                        </>
                    }
                </>
            }
            {dialog.tab === 'referral' &&
                <>
                    {referral.teaching &&
                        <>
                            <Select
                                disabled
                                animation="slide-down"
                                dropdown={false}
                                inputStyle="outline"
                                label={locale.candidateReferral.h}
                                labelStyle="floating"
                                rows={locale.candidateReferral.z.length}
                                display="anchored"
                                touchUi={false}
                                value={referral.teaching}
                                data={locale.candidateReferral.z}
                            />
                            <div className="cs-candidate-referral-input-text">
                                <p className="mbsc-txt-muted mbsc-txt-s mbsc-bold">{locale.candidateReferral.i}</p>
                            </div>
                        </>
                    }
                    {referral.collaboration &&
                        <>
                            <Select
                                disabled
                                animation="slide-down"
                                dropdown={false}
                                inputStyle="outline"
                                label={locale.candidateReferral.j}
                                labelStyle="floating"
                                rows={locale.candidateReferral.z.length}
                                display="anchored"
                                touchUi={false}
                                value={referral.collaboration}
                                data={locale.candidateReferral.z}
                            />
                            <div className="cs-candidate-referral-input-text">
                                <p className="mbsc-txt-muted mbsc-txt-s mbsc-bold">{locale.candidateReferral.k}</p>
                            </div>
                        </>
                    }
                    {referral.professionalism &&
                        <>
                            <Select
                                disabled
                                animation="slide-down"
                                dropdown={false}
                                inputStyle="outline"
                                label={locale.candidateReferral.l}
                                labelStyle="floating"
                                rows={locale.candidateReferral.z.length}
                                display="anchored"
                                touchUi={false}
                                value={referral.professionalism}
                                data={locale.candidateReferral.z}
                            />
                            <div className="cs-candidate-referral-input-text">
                                <p className="mbsc-txt-muted mbsc-txt-s mbsc-bold">{locale.candidateReferral.m}</p>
                            </div>
                        </>
                    }
                    {referral.personal &&
                        <>
                            <Select
                                disabled
                                animation="slide-down"
                                dropdown={false}
                                inputStyle="outline"
                                label={locale.candidateReferral.n}
                                labelStyle="floating"
                                rows={locale.candidateReferral.z.length}
                                display="anchored"
                                touchUi={false}
                                value={referral.personal}
                                data={locale.candidateReferral.z}
                            />
                            <div className="cs-candidate-referral-input-text">
                                <p className="mbsc-txt-muted mbsc-txt-s mbsc-bold">{locale.candidateReferral.o}</p>
                            </div>
                        </>
                    }
                    {referral.leadership &&
                        <>
                            <Select
                                disabled
                                animation="slide-down"
                                dropdown={false}
                                inputStyle="outline"
                                label={locale.candidateReferral.p}
                                labelStyle="floating"
                                rows={locale.candidateReferral.z.length}
                                display="anchored"
                                touchUi={false}
                                value={referral.leadership}
                                data={locale.candidateReferral.z}
                            />
                            <div className="cs-candidate-referral-input-text">
                                <p className="mbsc-txt-muted mbsc-txt-s mbsc-bold">{locale.candidateReferral.q}</p>
                            </div>
                        </>
                    }
                </>
            }
            {dialog.tab === 'comments' &&
                <>
                    {referral.hireAgain &&
                        <Select
                            disabled
                            animation="slide-down"
                            dropdown={false}
                            inputStyle="outline"
                            label={locale.candidateReferral.x}
                            labelStyle="floating"
                            rows={locale.candidateReferral.aa.length}
                            display="anchored"
                            touchUi={false}
                            value={referral.hireAgain}
                            data={locale.candidateReferral.aa}
                        />
                    }
                    {referral.comments &&
                        <>
                            <div className="cs-candidate-referral-helper">
                                <p className="mbsc-txt-muted mbsc-bold">{locale.candidateReferral.r}</p>
                            </div>
                            <div className="cs-candidate-referral-textarea">
                                <Textarea disabled label={locale.candidateReferral.s} inputStyle="outline" labelStyle="floating" value={referral.comments} />
                            </div>
                        </>
                    }
                </>
            }
        </Popup>
    );
}

export default CandidateReferral;

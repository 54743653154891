import React, {useContext, useCallback, useEffect} from 'react';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import dayjs from 'dayjs';
import '../styles/jobCandidates.css';
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";

function JobCandidates({locale, job, setCandidate, Navigation, width}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);

    const ListItem = data => {
        return <li key={data.item.email}>
            <div className="mbsc-row cs-job-candidates-results-list">
                {data.item.avatar &&  width > 768 &&
                    <div style={{backgroundImage: "url(https://www.gravatar.com/avatar/" + data.item.avatar + "?d=robohash)"}} className="cs-job-candidates-avatar" />
                }
                {!data.item.avatar && width > 768 &&
                    <div style={{backgroundImage: "url('https://www.gravatar.com/avatar/" + (+ new Date()) + "?d=robohash')"}} className="cs-candidates-results-avatar" />
                }
                <div className="cs-job-candidates-results-file">
                    <div className="mbsc-row">
                        <h4 className="cs-job-candidates-results-file-text">
                            {data.item.firstName && data.item.firstName} {data.item.lastName && data.item.lastName}
                        </h4>
                    </div>
                    <div>
                        {data.item.email} | {locale.jobCandidates.a}: {dayjs(data.item.timestamp).format("MMMM D, YYYY")}
                    </div>
                </div>
            </div>
        </li>;
    }

    const ListOpen = data => {
        const message = {
            type: 'wss',
            path: 'user-candidate',
            action: 'get',
            data: {
                candidate: job.candidates[data].email
            }
        };
        wsCall(message);
    }

    const GetUserCandidate = useCallback(data => {
        if (data.candidate) {
            setCandidate(data);

            Navigation({tab: 'candidate', item: 'information', save: true});
        }
    }, [Navigation]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'user-candidate') {
            GetUserCandidate(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetUserCandidate, setWsResponse]);

    return (
        <>
            {job.candidates.length === 0 &&
                <div className="cs-job-candidates-helper mbsc-bold mbsc-txt-muted">
                    <p>{locale.jobCandidates.b}</p>
                </div>
            }
            {job.candidates.length !== 0 &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    itemType={ListItem}
                    data={(job.candidates && job.candidates.length !== 0) ? job.candidates : []}
                    onItemTap={event => ListOpen(event.index)}
                />
            }
        </>
    );
}

export default JobCandidates;

import React, {useCallback, useContext, useState} from 'react';
import {Popup, toast, Input, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/usersNew.css';
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function UsersNew({locale, Dialog, dialog, Directory}) {
    const {wsCall} = useContext(NetworkContext);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');

    const DialogClose = useCallback(() => {
        Directory();
        Dialog({view: 'close'});
    }, [Dialog, Directory]);

    const ButtonSave = () => {
        if (!email) {
            toast({message: locale.usersNew.m, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (!password) {
            toast({message: locale.usersNew.l, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (password && password !== confirm) {
            toast({message: locale.usersNew.j, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            const message = {
                type: 'wss',
                path: 'user',
                action: 'put',
                data: {
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                    password: password
                }
            };
            wsCall(message);
        }
    }

    return (
        <Popup className="cs-users-new-popup" width={600} closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} display="center" headerText={locale.usersNew.a} buttons={[{text: locale.usersNew.i, cssClass: 'cs-users-new-cancel', handler: () => DialogClose()}, {text: locale.usersNew.h, cssClass: 'cs-users-new-save', handler: () => ButtonSave()}]} isOpen={dialog.view === 'usersNew'} onClose={DialogClose}>
            <Input inputStyle="underline" label={locale.usersNew.f} labelStyle="floating" type="text" name="email" value={email} onChange={ev => setEmail(ev.target.value)} />
            <Input inputStyle="underline" label={locale.usersNew.b} labelStyle="floating" type="text" name="firstName" value={firstName} onChange={ev => setFirstName(ev.target.value)} />
            <Input inputStyle="underline" label={locale.usersNew.c} labelStyle="floating" type="text" name="lastName" value={lastName} onChange={ev => setLastName(ev.target.value)} />
            <Input inputStyle="underline" label={locale.usersNew.d} labelStyle="floating" minLength="8" passwordToggle={true} type="password" name="Password" value={password} onChange={ev => setPassword(ev.target.value)} />
            <Input inputStyle="underline" label={locale.usersNew.e} labelStyle="floating" minLength="8" passwordToggle={true} type="password" name="Confirm" value={confirm} onChange={ev => setConfirm(ev.target.value)} />
        </Popup>
    );
}

export default UsersNew;

import React, {useState, useContext, useEffect, useCallback} from 'react';
import {confirm, toast, Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMemoPad, faTags} from "@fortawesome/pro-duotone-svg-icons";
import '../styles/candidatesFavorites.css';
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";
import CandidatesFavoritesTags from "../dialogs/CandidatesFavoritesTags";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function CandidatesFavorites({locale, Dialog, dialog, width, setCandidate, Navigation}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [favorites, setFavorites] = useState([]);
    const [favorite, setFavorite] = useState('');

    const Candidates = () => {
        const message = {
            type: 'wss',
            path: 'user-candidates',
            action: 'get',
            data: {}
        };
        wsCall(message);
    }

    const ClickCandidate = data => {
        const message = {
            type: 'wss',
            path: 'user-candidate',
            action: 'get',
            data: {
                candidate: data
            }
        };
        wsCall(message);
    }

    const ClickDelete = data => {
        const itemUser = data.item.user;
        let itemTag = data.tag;

        confirm({
            title: locale.candidatesFavorites.a,
            message: locale.candidatesFavorites.b,
            okText: locale.candidatesFavorites.c,
            cancelText: locale.candidatesFavorites.d,
            callback: (res) => {
                if (res) {
                    let array = favorites;
                    array = array.map(function(itemFavorite){
                        if (itemFavorite.user === itemUser){
                            itemFavorite.tags = itemFavorite.tags.filter(function(item){
                                return item !== itemTag;
                            })
                            ItemSave({user: itemUser, tags: itemFavorite.tags});
                        }
                        return itemFavorite;
                    })
                    setFavorites(array);
                }
            }
        });
    }

    const ItemSave = data => {
        const message = {
            type: 'wss',
            path: 'user-tags',
            action: 'update',
            data: {
                candidate: data.user,
                tags: data.tags
            }
        };
        wsCall(message);
    }

    const ButtonTag = data => {
        setFavorite(data);
        Dialog({view: 'candidatesFavoritesTags'});
    }

    const ListItem = data => {
        return <li key={data.item.user}>
            <div className="mbsc-row">
                <div className="mbsc-col">
                    <div className="mbsc-row cs-candidates-favorites-results-list">
                        {data.item.avatar && width > 768 &&
                            <div onClick={(ev) => ClickCandidate(data.item.user)} style={{backgroundImage: "url(https://www.gravatar.com/avatar/" + data.item.avatar + "?d=robohash)"}} className="cs-candidates-favorites-results-avatar" />
                        }
                        {!data.item.avatar && width > 768 &&
                            <div onClick={(ev) => ClickCandidate(data.item.user)} style={{backgroundImage: "url('https://www.gravatar.com/avatar/" + (+ new Date()) + "?d=robohash')"}} className="cs-candidates-favorites-results-avatar" />
                        }
                        <div className="cs-candidates-favorites-results-file">
                            <div className="mbsc-row">
                                <h4 className="cs-candidates-favorites-job-text" onClick={(ev) => ClickCandidate(data.item.user)}>
                                    {data.item.firstName && data.item.firstName} {data.item.lastName && data.item.lastName}
                                </h4>
                                {data.item.applications && data.item.applications.length !== 0 && data.item.applications.map((item) => {
                                    return (
                                        <div className="mbsc-note cs-candidates-favorites-note">
                                            {item.title}
                                        </div>
                                    );
                                })}
                                {data.item.text &&
                                    <FontAwesomeIcon className="cs-candidates-favorites-icon" icon={faMemoPad} />
                                }
                                {data.item.tags && data.item.tags.length !== 0 && data.item.tags.map((item) => {
                                    return (
                                        <div className="mbsc-note mbsc-note-success cs-candidates-favorites-note">
                                            {item}<span onClick={(ev) => {ClickDelete({item: data.item, tag: item})}}>&nbsp;&nbsp;&nbsp;X</span>
                                        </div>
                                    );
                                })}
                            </div>
                            <div onClick={(ev) => ClickCandidate(data.item.user)}>
                                {data.item.user}
                            </div>
                        </div>

                    </div>
                </div>
                <div className="mbsc-col-auto cs-candidates-favorites-file-button">
                    <Button color="primary" variant="flat" className="mbsc-bold" onClick={() => ButtonTag(data.item.user)} >
                        <FontAwesomeIcon className="cs-candidates-favorites-icon-upload" icon={faTags} /> {locale.candidatesFavorites.e}
                    </Button>
                </div>
            </div>
        </li>;
    }

    const GetUserCandidates = useCallback(data => {
        if (data.candidates) {
            setFavorites(data.candidates);
        }
    }, []);

    const UpdateUserTags = useCallback(data => {
        if (data.toast) {
            toast({message: locale.candidatesFavorites.i, color: 'info', duration: 1000, display: 'bottom'});
        }
    }, [locale.candidatesFavorites.i]);

    const GetUserCandidate = useCallback(data => {
        if (data.candidate) {
            setCandidate(data);

            Navigation({tab: 'candidate', item: 'information', save: true});
        }
    }, [Navigation]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'user-candidate') {
            GetUserCandidate(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetUserCandidate, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'user-tags') {
            UpdateUserTags(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateUserTags, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'user-candidates') {
            GetUserCandidates(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetUserCandidates, setWsResponse]);

    useEffect(() => {
        toast({message: locale.candidatesFavorites.g, color: 'info', duration: 1000, display: 'bottom'});
        Candidates();
    }, []);

    return (
        <>
            {favorites.length === 0 &&
                <div className="cs-candidates-favorites-helper mbsc-bold mbsc-txt-muted">
                    <p>{locale.candidatesFavorites.h}</p>
                </div>
            }
            <Listview
                theme="ios"
                themeVariant="light"
                itemType={ListItem}
                data={favorites}
            />
            {dialog.view === 'candidatesFavoritesTags' &&
                <CandidatesFavoritesTags
                    locale={locale}
                    Dialog={Dialog}
                    dialog={dialog}
                    favorites={favorites}
                    setFavorites={setFavorites}
                    favorite={favorite}
                />
            }
        </>
    );
}

export default CandidatesFavorites;

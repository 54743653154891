import React, {useState, useContext, useCallback, useEffect} from 'react';
import {SegmentedGroup, Segmented, Input, Select, Button, confirm, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/sharedJob.css';
import DayJS from 'react-dayjs';
import dayjs from 'dayjs';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHeartCirclePlus, faHeartCircleMinus, faLink, faPaperPlane, faPersonCirclePlus, faPersonCircleMinus} from "@fortawesome/pro-duotone-svg-icons";
import Empty from '../utils/Empty';
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {NetworkContext} from "../utils/NetworkContext";

function Job({locale, user, job, jobData, setSchool, Navigation, navigation}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [favorite, setFavorite] = useState(jobData.notes.favorite);
    const [notes, setNotes] = useState(jobData.notes);
    const [application, setApplication] = useState(jobData.application);

    const School = () => {
        const message = {
            type: 'wss',
            path: 'school',
            action: 'get',
            data: {
                id: job.account
            }
        };
        wsCall(message);
    }

    const SegmentedTab = data => {
        Navigation({item: data.target.value});
    }

    const ButtonNotes = data => {
        const item = {...notes};
        if (data === 'favorite') {
            if (item.favorite === false) {
                item.favorite = true;
                setFavorite(true);
            } else {
                item.favorite = false;
                setFavorite(false);
            }
        }
        setNotes(item);

        const message = {
            type: 'wss',
            path: 'job-notes',
            action: 'put',
            data: {
                private: item,
                shared: jobData.notesShared,
                job: job.id,
                school: job.account,
                logo: job.logo,
                name: job.name,
                title: job.title
            }
        };
        wsCall(message);
    }

    const ButtonApply = () => {
        confirm({
            title: locale.sharedJob.v,
            message: locale.sharedJob.w,
            okText: locale.sharedJob.x,
            cancelText: locale.sharedJob.ad,
            callback: (res) => {
                if (res) {
                    const item = {
                        id: job.id,
                        title: job.title,
                        name: job.name,
                        account: job.account,
                        logo: job.logo ? job.logo : null
                    };
                    setApplication(item);

                    const message = {
                        type: 'wss',
                        path: 'job-candidate',
                        action: 'put',
                        data: item
                    };
                    wsCall(message);
                }
            }
        });
    }

    const ButtonWithdraw = data => {
        confirm({
            title: locale.sharedJob.ai,
            message: locale.sharedJob.aj,
            okText: locale.sharedJob.ak,
            cancelText: locale.sharedJob.ad,
            callback: (res) => {
                if (res) {
                    setApplication({});

                    const message = {
                        type: 'wss',
                        path: 'job-candidate',
                        action: 'delete',
                        data: data ? {id: data} : {id: job.id}
                    };
                    wsCall(message);
                }
            }
        });
    }

    const ButtonEmail = () => {
        const item = {
            id: job.id,
            email: true
        };
        JobLog(item);

        const text = "mailto:" + job.email + "?subject=" + job.title;
        window.open(text, '_blank', 'noopener,noreferrer');
    }

    const ButtonUrl = () => {
        const item = {
            id: job.id,
            url: true
        };
        JobLog(item);

        window.open(job.url, '_blank', 'noopener,noreferrer');
    }

    const JobLog = data => {
        const message = {
            type: 'wss',
            path: 'job-candidate',
            action: 'update',
            data: data
        };
        wsCall(message);
    }

    const GetSchool = useCallback(data => {
        if (data.school) {
            setSchool(data);

            Navigation({tab: 'school', item: 'school', save: true});
        }
    }, [Navigation]);

    const PutJobNotes = useCallback(data => {
        if (data.toast) {
            toast({message: locale.sharedJob.an, color: 'info', duration: 1000, display: 'bottom'});
        }
    }, [locale.sharedJob.an]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'job-notes') {
            PutJobNotes(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutJobNotes, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'school') {
            GetSchool(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetSchool, setWsResponse]);

    useEffect(() => {
        if (navigation.menu !== locale.sharedJob.c) {
            Navigation({menu: locale.sharedJob.c});
        } else if (!navigation.item) {
            Navigation({item: 'job'});
        }
    }, []);

    return (
        <>
            <div className="mbsc-row cs-shared-job-main">
                {job.logo ?
                    <div style={{backgroundImage: "url(" + job.logo + ")"}} className="cs-shared-job-avatar" />
                    :
                    <div style={{backgroundImage: "url('https://www.gravatar.com/avatar/" + (+ new Date()) + "?d=identicon')"}} className="cs-shared-job-avatar" />
                }
                <div>
                    <div>
                        <h4 className="cs-shared-job-header-title">{job.title}</h4>
                    </div>
                    <div onClick={(ev) => navigation.page === 'jobs' && School()}>
                        <span className={navigation.page === 'jobs' ? 'cs-shared-job-links' : ''}>
                            {navigation.page === 'jobs' && job.name + ' | '}
                            {job.country && job.country}
                            {job.city && <span> | </span>}
                            {job.city && job.city}
                            {job.state && <span> | </span>}
                            {job.state && job.state}
                        </span>
                    </div>
                </div>
            </div>
            <div className="mbsc-row cs-shared-job-main">
                <div className="mbsc-col">
                    <SegmentedGroup theme="ios" themeVariant="light" className="cs-shared-job-nav" name="jobsJob" color="primary" value={navigation.item} onChange={SegmentedTab}>
                        <Segmented value="job" theme="ios" themeVariant="light" >
                            {locale.sharedJob.y}
                        </Segmented>
                        {(job.email || job.url || job.firstName || job.lastName) &&
                            <Segmented value="contact" theme="ios" themeVariant="light" >
                                {locale.sharedJob.z}
                            </Segmented>
                        }
                        {job.overview &&
                            <Segmented value="overview" theme="ios" themeVariant="light" >
                                {locale.sharedJob.b}
                            </Segmented>
                        }
                        {job.requirements &&
                            <Segmented value="requirements" theme="ios" themeVariant="light" >
                                {locale.sharedJob.s}
                            </Segmented>
                        }
                        {job.responsibilities &&
                            <Segmented value="responsibilities" theme="ios" themeVariant="light" >
                                {locale.sharedJob.t}
                            </Segmented>
                        }
                    </SegmentedGroup>
                </div>
            </div>
            {navigation.item === 'job' &&
                <div className="mbsc-row cs-shared-job-main">
                    <div className="mbsc-col cs-shared-job-col">
                        <Input disabled theme="ios" themeVariant="light" inputStyle="outline" label={locale.sharedJob.aa} labelStyle="floating" type="text" name="timestamp" value={dayjs(job.timestamp).format("MMMM D, YYYY")} />
                        {job.type &&
                            <Select
                                disabled
                                theme="ios"
                                themeVariant="light"
                                animation="slide-down"
                                dropdown={false}
                                inputStyle="outline"
                                label={locale.sharedJob.p}
                                labelStyle="floating"
                                rows={locale.sharedJob.ap.length}
                                display="anchored"
                                touchUi={false}
                                value={job.type}
                                data={locale.sharedJob.ap}
                            />
                        }
                        {job.grade &&
                            <Select
                                disabled
                                theme="ios"
                                themeVariant="light"
                                animation="slide-down"
                                dropdown={false}
                                inputStyle="outline"
                                label={locale.sharedJob.h}
                                labelStyle="floating"
                                rows={locale.sharedJob.aq.length}
                                display="anchored"
                                touchUi={false}
                                value={job.grade}
                                data={locale.sharedJob.aq}
                            />
                        }
                        {job.role &&
                            <Select
                                disabled
                                theme="ios"
                                themeVariant="light"
                                animation="slide-down"
                                dropdown={false}
                                inputStyle="outline"
                                label={locale.sharedJob.m}
                                labelStyle="floating"
                                rows={locale.sharedJob.ar.length}
                                display="anchored"
                                touchUi={false}
                                value={job.role}
                                data={locale.sharedJob.ar}
                            />
                        }
                    </div>
                </div>
            }
            {navigation.item === 'overview' &&
                <div className="cs-shared-job-col-ck mbsc-padding">
                    <CKEditor id="editor-focus" editor={ClassicEditor} config={{toolbar: []}} disabled={true} data={job.overview} />
                </div>
            }
            {navigation.item === 'requirements' &&
                <div className="cs-shared-job-col-ck mbsc-padding">
                    <CKEditor id="editor-focus" editor={ClassicEditor} config={{toolbar: []}} disabled={true} data={job.requirements} />
                </div>
            }
            {navigation.item === 'responsibilities' &&
                <div className="cs-shared-job-col-ck mbsc-padding">
                    <CKEditor id="editor-focus" editor={ClassicEditor} config={{toolbar: []}} disabled={true} data={job.responsibilities} />
                </div>
            }
            {navigation.item === 'contact' &&
                <div className="mbsc-row cs-shared-job-main">
                    <div className="mbsc-col cs-shared-job-col">
                        {Empty(application) && !user.options.superAdmin &&
                            <div className="cs-shared-job-helper mbsc-bold mbsc-txt-muted">
                                <p>{locale.sharedJob.ab}</p>
                            </div>
                        }
                        {Empty(application) && user.options.superAdmin &&
                            <div className="cs-shared-job-helper mbsc-bold mbsc-txt-muted">
                                <p>{locale.sharedJob.ao}</p>
                            </div>
                        }
                        {!Empty(application) && job.email &&
                            <Input disabled theme="ios" themeVariant="light" inputStyle="outline" label={locale.sharedJob.ac} labelStyle="floating" type="email" name="email" value={job.email} />
                        }
                        {!Empty(application) && job.firstName &&
                            <Input disabled theme="ios" themeVariant="light" inputStyle="outline" label={locale.sharedJob.q} labelStyle="floating" type="text" name="firstName" value={job.firstName} />
                        }
                        {!Empty(application) && job.lastName &&
                            <Input disabled theme="ios" themeVariant="light" inputStyle="outline" label={locale.sharedJob.r} labelStyle="floating" type="text" name="lastName" value={job.lastName} />
                        }
                        {!Empty(application) && job.url &&
                            <Input disabled theme="ios" themeVariant="light" inputStyle="outline" label={locale.sharedJob.u} labelStyle="floating" type="text" name="url" value={job.url} />
                        }
                    </div>
                </div>
            }
            <div className="mbsc-row cs-shared-job-footer">
                <div className="mbsc-col cs-shared-job-footer-actions">
                    <div className="mbsc-row">
                        {(Empty(application) || (!Empty(application) && navigation.item !== 'contact')) &&
                            <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="mbsc-bold" onClick={() => ButtonNotes('favorite')} >
                                <FontAwesomeIcon className="cs-shared-job-button-icon" icon={favorite === false ? faHeartCirclePlus : faHeartCircleMinus} /> {favorite === false ? locale.sharedJob.al : locale.sharedJob.am}
                            </Button>
                        }
                        {Empty(application) && !user.options.superAdmin &&
                            <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="mbsc-bold" onClick={() => ButtonApply()} >
                                <FontAwesomeIcon className="cs-shared-job-button-icon" icon={faPersonCirclePlus} /> {locale.sharedJob.ae}
                            </Button>
                        }
                        {!Empty(application) && navigation.item !== 'contact' &&
                            <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="mbsc-bold" onClick={() => ButtonWithdraw()} >
                                <FontAwesomeIcon className="cs-shared-job-button-icon" icon={faPersonCircleMinus} /> {locale.sharedJob.ai}
                            </Button>
                        }
                        {!Empty(application) && job.email && navigation.item === 'contact' &&
                            <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="mbsc-bold" onClick={() => ButtonEmail()} >
                                <FontAwesomeIcon className="cs-shared-job-button-icon" icon={faPaperPlane} /> {locale.sharedJob.ag}
                            </Button>
                        }
                        {!Empty(application) && job.url && navigation.item === 'contact' &&
                            <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="mbsc-bold" onClick={() => ButtonUrl()} >
                                <FontAwesomeIcon className="cs-shared-job-button-icon" icon={faLink} /> {locale.sharedJob.ah}
                            </Button>
                        }
                    </div>
                </div>
                <div className="mbsc-col-auto cs-shared-job-footer-note">
                    {!Empty(application) && application.timestamp &&
                        <div className="mbsc-txt-muted mbsc-italic">
                            {locale.sharedJob.af} <DayJS locale={user.locale} format="MMMM D, YYYY">{application.timestamp}</DayJS>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

export default Job;

import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Checkbox, Input, Select, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/userJob.css';
import DayJS from "react-dayjs";
import {NetworkContext} from "../utils/NetworkContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSealExclamation} from "@fortawesome/pro-duotone-svg-icons";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function UserJob({locale, width, user, email}) {
    const {wsCall} = useContext(NetworkContext);
    const [job, setJob] = useState(user.job);
    const [school, setSchool] = useState(user.school);
    const [division, setDivision] = useState(user.division);
    const [teacher, setTeacher] = useState(user.teacher);
    const [leader, setLeader] = useState(user.leader);
    const [toSave, setToSave] = useState(false);

    const Complete = () => {
        let item = '';

        if (!teacher && !leader) {

            item = item.concat(locale.userJob.n + '/' + locale.userJob.o);
        }
        if (!job) {

            item = item ? item.concat(', ' + locale.userJob.j) : item.concat(locale.userJob.j);
        }
        if (!school) {

            item = item ? item.concat(', ' + locale.userJob.k) : item.concat(locale.userJob.k);
        }
        if (!division) {

            item = item ? item.concat(', ' + locale.userJob.c) : item.concat(locale.userJob.c);
        }
        return item;
    }

    const InputBlur = useCallback(() => {
        const message = {
            type: 'wss',
            path: 'user',
            action: 'update',
            data: {
                email: email,
                job: job,
                school: school,
                division: division,
                teacher: teacher,
                leader: leader
            }
        };
        wsCall(message);
    }, [email, job, school, division, teacher, leader])

    useEffect(() => {
        if (toSave) {
            InputBlur();
            setToSave(false);
        }
    }, [toSave, InputBlur]);

    return (
        <>
            <div className="mbsc-row cs-user-job-main">
                <div className="mbsc-col">
                    {Complete() &&
                        <div className="cs-user-job-helper mbsc-row">
                            <FontAwesomeIcon className="cs-user-job-badge-incomplete" icon={faSealExclamation}/> <p className="mbsc-bold mbsc-txt-muted cs-user-job-error">{locale.userJob.a}</p>&nbsp;<p className="mbsc-txt-muted cs-user-job-error">{Complete()}</p>
                        </div>
                    }
                    <Checkbox
                        cssClass="cs-user-job-checkbox mbsc-bold"
                        color="info"
                        position="start"
                        label={locale.userJob.n}
                        description={locale.userJob.h}
                        checked={teacher}
                        onChange={(ev) => {setTeacher(ev.target.checked); setToSave(true);}}
                    />
                    <Checkbox
                        cssClass="cs-user-job-checkbox mbsc-bold"
                        color="info"
                        position="start"
                        label={locale.userJob.o}
                        description={locale.userJob.i}
                        checked={leader}
                        onChange={(ev) => {setLeader(ev.target.checked); setToSave(true);}}
                    />
                    <Input inputStyle="outline" label={locale.userJob.j} labelStyle="floating" type="text" name="job" value={job} onBlur={InputBlur} onChange={ev => setJob(ev.target.value)} />
                    <Input inputStyle="outline" label={locale.userJob.k} labelStyle="floating" type="text" name="school" value={school} onBlur={InputBlur} onChange={ev => setSchool(ev.target.value)} />
                    <Select
                        animation="slide-down"
                        dropdown={false}
                        inputStyle="outline"
                        label={locale.userJob.c}
                        labelStyle="floating"
                        rows={locale.userJob.q.length}
                        display="anchored"
                        touchUi={false}
                        value={division}
                        data={locale.userJob.q}
                        onChange={args => {setDivision(args.value); setToSave(true);}}
                    />
                </div>
            </div>
            {user.lastLogin && width > 992 &&
                <div className="mbsc-row cs-user-job-footer">
                    <div className="mbsc-col cs-user-job-footer-actions" />
                    <div className="mbsc-col-auto cs-user-job-footer-note">
                        <div className="mbsc-txt-muted mbsc-italic">{locale.userJob.b} <DayJS locale={user.locale} format="MMMM D, YYYY h:mm A">{user.lastLogin}</DayJS></div>
                    </div>
                </div>
            }
        </>
    );
}

export default UserJob;

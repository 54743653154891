import React, {useCallback, useContext, useState} from 'react';
import {Input, Popup, setOptions, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/userReset.css';
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function UserReset({locale, Dialog, dialog, user}) {
    const {wsCall} = useContext(NetworkContext);
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonSave = () => {
        if (!password || !confirm) {
            toast({message: locale.userReset.a, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (password && confirm && password !== confirm) {
            toast({message: locale.userReset.b, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            const message = {
                type: 'wss',
                path: 'user',
                action: 'update',
                data: {
                    email: user.email,
                    password: password
                }
            };
            wsCall(message);
        }
    }

    return (
        <Popup className="cs-user-reset-popup" width={600} closeOnOverlayClick={false} closeOnEsc={false} display="center" headerText={locale.userReset.c} buttons={[{text: locale.userReset.f, cssClass: 'cs-user-reset-cancel', handler: ()=> DialogClose()},{text: locale.userReset.g, cssClass: 'cs-user-reset-save', handler: () => ButtonSave()}]} isOpen={dialog.view === 'userReset'} onClose={DialogClose}>
            <div className="mbsc-padding cs-user-reset-helper">
                <p className="mbsc-txt-muted">
                    {locale.userReset.i}
                </p>
            </div>
            <Input inputStyle="underline" label={locale.userReset.d} labelStyle="floating" type="password" passwordToggle={true} minLength="8" name="password" value={password} onChange={ev => setPassword(ev.target.value)} />
            <Input inputStyle="underline" label={locale.userReset.e} labelStyle="floating" type="password" passwordToggle={true} minLength="8" name="confirm" value={confirm} onChange={ev => setConfirm(ev.target.value)} />
        </Popup>
    );
}

export default UserReset;

import React, {useCallback, useEffect, useState, useContext} from 'react';
import {SegmentedGroup, Segmented, Button, Select, toast, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/users.css';
import Empty from "../utils/Empty";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPersonCirclePlus} from "@fortawesome/pro-duotone-svg-icons";
import Notes from "../shared/Notes";
import UsersUser from "../dialogs/UsersUser";
import UsersNew from "../dialogs/UsersNew";
import Item from "../navigation/Item";
import Menu from "../navigation/Menu";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function Users({locale, width, Navigation, Dialog, account, dialog, user, navigation, userData, setUserData}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [users, setUsers] = useState([]);

    const Directory = () => {
        const message = {
            type: 'wss',
            path: 'users',
            action: 'get',
            data: {}
        };
        wsCall(message);
    }

    const ButtonNew = () => {
        Dialog({view: 'usersNew'});
    }

    const SelectUser = data => {
        const message = {
            type: 'wss',
            path: 'user',
            action: 'get',
            data: {
                user: data
            }
        };
        wsCall(message);
    }

    const GetUsers = useCallback(data => {
        if (data.users) {
            setUsers(data.users);
        }
    }, []);

    const GetUser = useCallback(data => {
        if (data.user) {
            setUserData(data.user);
            Dialog({view: 'usersUser'});
        }
    }, [Dialog]);

    const PutUser = useCallback(data => {
        if (data.toast) {
            toast({message: locale.users.d, color: 'info', duration: 1000, display: 'bottom'});
        }
        if (data.error) {
            toast({message: locale.users.a, color: 'danger', duration: 3000, display: 'bottom'});
        }
        if (data.user) {
            setUserData(data.user);
            Dialog({view: "usersUser"});
        }
    }, [locale.users.a, locale.users.d, Dialog]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'user') {
            PutUser(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutUser, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'user') {
            GetUser(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetUser, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'users') {
            GetUsers(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetUsers, setWsResponse]);

    useEffect(() => {
        Directory();
        Navigation({menu: locale.users.c});
    }, []);

    return (
        <div className="mbsc-grid cs-users-main">
            <div className={width > 768 ? "mbsc-row cs-users-container" : "mbsc-row cs-users-container-xs"}>
                <div className="mbsc-col cs-users-title">
                    <p className={width > 768 ? "mbsc-txt-l" : "mbsc-txt-m"}>
                        {account.name}
                    </p>
                </div>
            </div>
            <div className="mbsc-row">
                {width > 768 &&
                    <div className="mbsc-col-12 mbsc-col-md-3 mbsc-col-sm-12 cs-users-left">
                        {navigation.menu && navigation.menu.length !== 0 &&
                            <Listview
                                theme="ios"
                                themeVariant="light"
                                select="single"
                                itemType={event => Item(event, navigation, null)}
                                data={navigation.menu}
                                onItemTap={event => Menu(event.index, Navigation, navigation)}
                            />
                        }
                        <Notes
                            locale={locale}
                            dialog={dialog}
                            Dialog={Dialog}
                            user={user}
                        />
                    </div>
                }
                <div className="mbsc-col-12 mbsc-col-md-9 mbsc-col-sm-12 cs-users-right">
                    {navigation.menu && navigation.menu.length !== 0 && width < 768 &&
                        <div className="mbsc-row cs-users-section">
                            <div className="mbsc-col">
                                <SegmentedGroup className="cs-users-nav" name="jobs" color="primary" value={navigation.tab} onChange={ev => Menu(ev, Navigation, navigation)}>
                                    {navigation.menu.map((item) => {
                                        return (
                                            <Segmented value={item.name} >
                                                {item.label}
                                            </Segmented>
                                        );
                                    })}
                                </SegmentedGroup>
                            </div>
                        </div>
                    }
                    <div className="mbsc-row cs-users-section">
                        <Select
                            rows={users.length < 10 ? users.length : 10}
                            display="inline"
                            touchUi={false}
                            filter={true}
                            data={users}
                            onChange={(args, inst) => {SelectUser(args.value); inst.setVal(null);}}
                        />
                    </div>
                    <div className="mbsc-row cs-users-footer">
                        <Button color="primary" variant="flat" className="mbsc-bold" onClick={() => ButtonNew()} >
                            <FontAwesomeIcon className="cs-users-icon" icon={faPersonCirclePlus} /> {locale.users.b}
                        </Button>
                    </div>
                </div>
            </div>
            {dialog.view === 'usersUser' &&
                <UsersUser
                    locale={locale}
                    Dialog={Dialog}
                    dialog={dialog}
                    userData={userData}
                    user={user}
                    Directory={Directory}
                />
            }
            {dialog.view === 'usersNew' &&
                <UsersNew
                    locale={locale}
                    Dialog={Dialog}
                    dialog={dialog}
                    Directory={Directory}
                />
            }
        </div>
    );
}

export default Users;

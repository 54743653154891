import React, {useState, useContext} from 'react';
import {Checkbox, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title} from 'chart.js';
import '../styles/schoolDiversity.css';
import {Bar, Doughnut} from "react-chartjs-2";
import SchoolLeadershipEthnicity from "../dialogs/SchoolLeadershipEthnicity";
import SchoolLeadershipGender from "../dialogs/SchoolLeadershipGender";
import SchoolFacultyEthnicity from "../dialogs/SchoolFacultyEthnicity";
import SchoolFacultyGender from "../dialogs/SchoolFacultyGender";
import {NetworkContext} from "../utils/NetworkContext";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function SchoolDiversity({locale, account, Dialog, dialog}) {
    const {wsCall} = useContext(NetworkContext);
    const [lgbtqia, setLgbtqia] = useState(account.lgbtqia);

    const CheckboxUpdate = data => {
        setLgbtqia(data);

        const message = {
            type: 'wss',
            path: 'account',
            action: 'update',
            data: {
                id: account.id,
                lgbtqia: data
            }
        };
        wsCall(message);
    }

    return (
        <>
            <div className="mbsc-row cs-school-diversity-main">
                <div className="mbsc-col">
                    <Checkbox
                        cssClass="cs-school-diversity-checkbox mbsc-bold"
                        color="info"
                        position="start"
                        label={locale.schoolDiversity.f}
                        description={locale.schoolDiversity.e}
                        checked={lgbtqia}
                        onChange={(ev) => CheckboxUpdate(ev.target.checked)}
                    />
                </div>
            </div>
            <div className="mbsc-row cs-school-diversity-main">
                <div className="mbsc-col">
                    <div className="mbsc-row mbsc-justify-content-between cs-school-diversity-card-section">
                        <div className="mbsc-col-6 cs-school-diversity-card-section-left">
                            <div className="cs-school-diversity-card mbsc-padding">
                                {account.stats.leadershipEthnicity.array.length !== 0 ?
                                    <Bar options={account.stats.leadershipEthnicity.options} data={account.stats.leadershipEthnicity.data} />
                                    :
                                    <div className="cs-school-diversity-text">
                                        <h4 className="mbsc-txt-s mbsc-txt-muted cs-school-diversity-card-text-p">
                                            {locale.schoolDiversity.a}
                                        </h4>
                                    </div>
                                }
                            </div>
                            <span className="mbsc-desc cs-school-diversity-edit-field-right mbsc-bold">
                                    <a href="#" onClick={(ev) => {Dialog({view: 'schoolLeadershipEthnicity'})}}>
                                        {locale.schoolDiversity.g}
                                    </a>
                            </span>
                        </div>
                        <div className="mbsc-col-6 cs-school-diversity-card-section-right">
                            <div className="cs-school-diversity-card mbsc-padding">
                                {account.stats.leadershipGender.array.length !== 0 ?
                                    <Doughnut options={account.stats.leadershipGender.options} data={account.stats.leadershipGender.data} />
                                    :
                                    <div className="cs-school-diversity-text">
                                        <h4 className="mbsc-txt-s mbsc-txt-muted cs-school-diversity-card-text-p">
                                            {locale.schoolDiversity.b}
                                        </h4>
                                    </div>
                                }
                            </div>
                            <span className="mbsc-desc cs-school-diversity-edit-field-right mbsc-bold">
                                    <a href="#" onClick={(ev) => {Dialog({view: 'schoolLeadershipGender'})}}>
                                        {locale.schoolDiversity.h}
                                    </a>
                            </span>
                        </div>
                    </div>
                    <div className="mbsc-row mbsc-justify-content-between cs-school-diversity-card-section">
                        <div className="mbsc-col-6 cs-school-diversity-card-section-left">
                            <div className="cs-school-diversity-card mbsc-padding">
                                {account.stats.facultyGender.array.length !== 0 ?
                                    <Doughnut options={account.stats.facultyGender.options} data={account.stats.facultyGender.data} />
                                    :
                                    <div className="cs-school-diversity-text">
                                        <h4 className="mbsc-txt-s mbsc-txt-muted cs-school-diversity-card-text-p">
                                            {locale.schoolDiversity.d}
                                        </h4>
                                    </div>
                                }
                            </div>
                            <span className="mbsc-desc cs-school-diversity-edit-field-right mbsc-bold">
                                    <a href="#" onClick={(ev) => {Dialog({view: 'schoolFacultyGender'})}}>
                                        {locale.schoolDiversity.i}
                                    </a>
                            </span>
                        </div>
                        <div className="mbsc-col-6 cs-school-diversity-card-section-right">
                            <div className="cs-school-diversity-card mbsc-padding">
                                {account.stats.facultyEthnicity.array.length !== 0 ?
                                    <Bar options={account.stats.facultyEthnicity.options} data={account.stats.facultyEthnicity.data} />
                                    :
                                    <div className="cs-school-diversity-text">
                                        <h4 className="mbsc-txt-s mbsc-txt-muted cs-school-diversity-card-text-p">
                                            {locale.schoolDiversity.c}
                                        </h4>
                                    </div>
                                }
                            </div>
                            <span className="mbsc-desc cs-school-diversity-edit-field-right mbsc-bold">
                                    <a href="#" onClick={(ev) => {Dialog({view: 'schoolFacultyEthnicity'})}}>
                                        {locale.schoolDiversity.j}
                                    </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {dialog.view === "schoolLeadershipEthnicity" &&
                <SchoolLeadershipEthnicity
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    account={account}
                />
            }
            {dialog.view === "schoolLeadershipGender" &&
                <SchoolLeadershipGender
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    account={account}
                />
            }
            {dialog.view === "schoolFacultyEthnicity" &&
                <SchoolFacultyEthnicity
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    account={account}
                />
            }
            {dialog.view === "schoolFacultyGender" &&
                <SchoolFacultyGender
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    account={account}
                />
            }
        </>
    );
}

export default SchoolDiversity;

import React, {useState, useContext, useEffect, useCallback} from 'react';
import {setOptions, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMemoPad} from "@fortawesome/pro-duotone-svg-icons";
import '../styles/jobsFavorites.css';
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function JobsFavorites({locale, setJob, Navigation, width}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [favorites, setFavorites] = useState([]);

    const Jobs = () => {
        const message = {
            type: 'wss',
            path: 'jobs',
            action: 'get',
            data: {}
        };
        wsCall(message);
    }

    const ListOpen = data => {
        const message = {
            type: 'wss',
            path: 'job',
            action: 'get',
            data: {
                id: favorites[data].job
            }
        };
        wsCall(message);
    }

    const ListItem = data => {
        return <li key={data.item.id}>
            <div className="mbsc-row cs-jobs-favorites-list">
                {data.item.logo && width > 768 &&
                    <div style={{backgroundImage: "url(" + data.item.logo + ")"}} className="cs-jobs-favorites-avatar" />
                }
                {!data.item.logo && width > 768 &&
                    <div style={{backgroundImage: "url('https://www.gravatar.com/avatar/" + (+ new Date()) + "?d=identicon')"}} className="cs-jobs-favorites-avatar" />
                }
                <div className="cs-jobs-favorites-job">
                    <div className="mbsc-row">
                        <h4 className="cs-jobs-favorites-job-text">
                            {width < 576
                            ? data.item.title.length > 20
                                ? data.item.title.substring(0, 20) + '...'
                                : data.item.title
                            : data.item.title}
                        </h4>
                        {data.item.text &&
                            <FontAwesomeIcon className="cs-jobs-favorites-icon" icon={faMemoPad} />
                        }
                    </div>
                    <div>
                        {data.item.name}
                    </div>
                </div>
            </div>
        </li>;
    }

    const GetJobs = useCallback(data => {
        if (data.favorites) {
            setFavorites(data.favorites);
        }
    }, []);

    const GetJob = useCallback(data => {
        if (data.job) {
            setJob(data);

            Navigation({tab: 'job', item: 'job', save: true});
        }
    }, [Navigation]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'job') {
            GetJob(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetJob, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'jobs') {
            GetJobs(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetJobs, setWsResponse]);

    useEffect(() => {
        toast({message: locale.jobsFavorites.b, color: 'info', duration: 1000, display: 'bottom'});
        Jobs();
    }, []);

    return (
        <>
            {favorites.length === 0 &&
                <div className="cs-jobs-favorites-helper mbsc-bold mbsc-txt-muted">
                    <p>{locale.jobsFavorites.a}</p>
                </div>
            }
            <Listview
                theme="ios"
                themeVariant="light"
                itemType={ListItem}
                data={favorites}
                onItemTap={event => ListOpen(event.index)}
            />
        </>
    );
}

export default JobsFavorites;

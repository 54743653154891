import React, {useCallback, useState, useEffect, useContext} from 'react';
import {Popup, Input, Textarea, toast, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import Empty from "../utils/Empty";
import '../styles/loginHelp.css';
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function LoginHelp({locale, Dialog, dialog}) {
    const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [text, setText] = useState('');

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonHelp = () => {
        if (!email || !firstName || !lastName || !text) {
            toast({message: locale.loginHelp.h, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            const message = {
                type: 'rest',
                path: 'help',
                action: 'put',
                data: {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    text: text,
                    auth: false
                }
            };
            restCall(message);
        }
    }

    const PutHelp = useCallback(data => {
        if (data.toast) {
            toast({message: locale.loginHelp.i, color: 'info', duration: 1000, display: 'bottom'});
            DialogClose();
        }
    }, [locale.loginHelp.i, DialogClose]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'put' && restResponse.path === 'help') {
            PutHelp(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, PutHelp, setRestResponse]);

    return (
        <Popup className="cs-login-help-popup" width={600} closeOnOverlayClick={false} closeOnEsc={false} display="center" scrollLock={false} headerText={locale.loginHelp.c} buttons={[{text: locale.loginHelp.g, cssClass: 'cs-login-help-cancel', handler: () => DialogClose()},{text: locale.loginHelp.a, cssClass: 'cs-login-help-save', handler: () => ButtonHelp()}]} isOpen={dialog.view === 'loginHelp'} onClose={DialogClose}>
            <div className="cs-login-help-helper">
                <p className="mbsc-txt-muted">
                    <span className="mbsc-bold">{locale.loginHelp.j}</span> {locale.loginHelp.k}
                </p>
            </div>
            <Input inputStyle="underline" label={locale.loginHelp.e} labelStyle="floating" type="text" name="firstName" value={firstName} onChange={ev => setFirstName(ev.target.value)} />
            <Input inputStyle="underline" label={locale.loginHelp.f} labelStyle="floating" type="text" name="lastName" value={lastName} onChange={ev => setLastName(ev.target.value)} />
            <Input inputStyle="underline" label={locale.loginHelp.d} labelStyle="floating" type="email" name="Email" value={email} onChange={ev => setEmail(ev.target.value)} />
            <div className="cs-login-help-textarea">
                <Textarea inputStyle="underline" labelStyle="floating" label={locale.loginHelp.b} value={text} onChange={(ev) => setText(ev.target.value)} />
            </div>
        </Popup>
    );
}

export default LoginHelp;

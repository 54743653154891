import React, {useCallback, useContext, useEffect} from 'react';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/schoolsResults.css';
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

function SchoolsResults({results, setResult, setSchool, Navigation, width}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);

    const ListOpen = data => {
        setResult(results[data]);

        const message = {
            type: 'wss',
            path: 'school',
            action: 'get',
            data: {
                id: results[data].id
            }
        };
        wsCall(message);
    }

    const ListItem = data => {
        return <li>
            <div className="mbsc-row cs-schools-results-list">
                <div>
                    {data.item.logo && width > 768 &&
                        <div style={{backgroundImage: "url(" + data.item.logo + ")"}} className="cs-schools-results-avatar" />
                    }
                    {!data.item.logo && width > 768 &&
                        <div style={{backgroundImage: "url('https://www.gravatar.com/avatar/" + (+ new Date()) + "?d=identicon')"}} className="cs-schools-results-avatar" />
                    }
                </div>
                <div className="cs-schools-results-file">
                    <div className="mbsc-row">
                        <h4 className="cs-schools-results-file-text">
                            <span dangerouslySetInnerHTML={{__html: data.item.name}} />
                        </h4>
                    </div>
                    <div>
                        {data.item.country &&
                            <span dangerouslySetInnerHTML={{__html: data.item.country}} />
                        }
                        {data.item.city &&
                            <span> | </span>
                        }
                        {data.item.city &&
                            <span dangerouslySetInnerHTML={{__html: data.item.city}} />
                        }
                        {data.item.state &&
                            <span> | </span>
                        }
                        {data.item.state &&
                            <span dangerouslySetInnerHTML={{__html: data.item.state}} />
                        }
                    </div>
                </div>
            </div>
        </li>;
    }

    const GetSchool = useCallback(data => {
        if (data.school) {
            setSchool(data);

            Navigation({tab: 'school', item: 'school', save: true});
        }
    }, [Navigation]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'school') {
            GetSchool(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetSchool, setWsResponse]);

    return (
        <Listview
            theme="ios"
            themeVariant="light"
            itemType={ListItem}
            data={results}
            onItemTap={event => ListOpen(event.index)}
        />
    );
}

export default SchoolsResults;

import React, {useCallback, useContext, useEffect, useState} from 'react';
import {setOptions, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/jobDrafts.css';
import {NetworkContext} from "../utils/NetworkContext";
import dayjs from "dayjs";
import 'dayjs/locale/es';
import Empty from "../utils/Empty";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function JobDrafts({locale, user, setJob, Navigation, account, Division}) {
    dayjs.locale(user.locale);
    const [drafts, setDrafts] = useState([]);
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);

    const Jobs = () => {
        const message = {
            type: 'wss',
            path: 'jobs',
            action: 'get',
            data: {
                block: 'job'
            }
        };
        wsCall(message);
    }

    const ListOpen = data => {
        setJob(drafts[data]);

        Navigation({tab: 'job', save: true});
    }

    const ListItem = data => {
        return <li key={data.item.timestamp}>
            <div className="mbsc-row cs-job-drafts-list">
                {account.logo && account.logo.link &&
                    <div style={{backgroundImage: `url(${account.logo.link})`}} className="cs-job-drafts-avatar" />
                }
                {(!account.logo || !account.logo.link) &&
                    <div style={{backgroundImage: `url('https://www.gravatar.com/avatar/${+ new Date()}?d=identicon')`}} className="cs-job-drafts-avatar" />
                }
                <div className="cs-job-drafts-file">
                    <div className="mbsc-row">
                        <h4 className="cs-job-drafts-file-text">
                            {data.item.title}
                        </h4>
                    </div>
                    <div>
                        {Division(data.item.grade)} | {locale.jobDrafts.c}: {dayjs(data.item.timestamp).format("MMMM D, YYYY")}
                    </div>
                </div>
            </div>
        </li>;
    }

    const GetJobs = useCallback(data => {
        if (data.drafts) {
            setDrafts(data.drafts);
        }
    }, []);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'jobs') {
            GetJobs(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetJobs, setWsResponse]);

    useEffect(() => {
        toast({message: locale.jobDrafts.b, color: 'info', duration: 1000, display: 'bottom'});
        Jobs();
    }, []);

    return (
        <>
            {drafts.length === 0 &&
                <div className="cs-job-drafts-helper mbsc-bold mbsc-txt-muted">
                    <p>{locale.jobDrafts.a}</p>
                </div>
            }
            <Listview
                theme="ios"
                themeVariant="light"
                itemType={ListItem}
                data={drafts}
                onItemTap={event => ListOpen(event.index)}
            />
        </>
    );
}

export default JobDrafts;

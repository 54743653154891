import React, {useState, useContext} from 'react';
import {Checkbox, Input, setOptions, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/schoolInformation.css';
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function SchoolInformation({locale, account}) {
    const {wsCall} = useContext(NetworkContext);
    const [name, setName] = useState(account.name);
    const [students, setStudents] = useState(account.students);
    const [founded, setFounded] = useState(account.founded);
    const [grades, setGrades] = useState(account.grades);
    const [publish, setPublish] = useState(account.publish);

    const CheckboxUpdate = data => {
        setPublish(data);

        const message = {
            type: 'wss',
            path: 'account',
            action: 'update',
            data: {
                id: account.id,
                publish: data
            }
        };
        wsCall(message);
    }

    const InputBlur = () => {
        if (!name) {
            toast({message: locale.schoolInformation.g, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            const message = {
                type: 'wss',
                path: 'account',
                action: 'update',
                data: {
                    id: account.id,
                    name: name,
                    students: students,
                    founded: founded,
                    grades: grades
                }
            };
            wsCall(message);
        }
    }

    return (
        <>
            <div className="mbsc-row cs-school-information-main">
                <div className="mbsc-col">
                    <Checkbox
                        cssClass="cs-school-information-checkbox mbsc-bold"
                        color="info"
                        position="start"
                        label={publish ? locale.schoolInformation.f : locale.schoolInformation.e}
                        description={publish ? locale.schoolInformation.h : locale.schoolInformation.i}
                        checked={publish}
                        onChange={(ev) => CheckboxUpdate(ev.target.checked)}
                    />
                    <Input inputStyle="outline" label={locale.schoolInformation.a} labelStyle="floating" type="text" name="name" value={name} onBlur={InputBlur} onChange={ev => setName(ev.target.value)} />
                    <Input inputStyle="outline" label={locale.schoolInformation.b} labelStyle="floating" type="number" name="students" value={students} onBlur={InputBlur} onChange={ev => setStudents(ev.target.value)} />
                    <Input inputStyle="outline" label={locale.schoolInformation.c} labelStyle="floating" type="number" name="founded" value={founded} onBlur={InputBlur} onChange={ev => setFounded(ev.target.value)} />
                    <Input inputStyle="outline" label={locale.schoolInformation.d} labelStyle="floating" type="text" name="grades" value={grades} onBlur={InputBlur} onChange={ev => setGrades(ev.target.value)} />
                </div>
            </div>
        </>
    );
}

export default SchoolInformation;

import React from 'react';
import {Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChalkboardUser, faSchool, faClipboardListCheck, faPipe} from "@fortawesome/pro-duotone-svg-icons";
import '../styles/dashboardHeader.css';
import DashboardHeaderHelp from "../dialogs/DashboardHeaderHelp";
import DashboardHeaderCancel from "../dialogs/DashboardHeaderCancel";
import DashboardHeaderMenu from "../dialogs/DashboardHeaderMenu";
import DashboardHeaderMembership from "../dialogs/DashboardHeaderMembership";
import DashboardHeaderDiscount from "../dialogs/DashboardHeaderDiscount";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeader({locale, navigation, Navigation, dialog, Dialog, configuration, setAccount, user, setUser, token, wss, setLocale, width, account}) {

    return (
        <div className="mbsc-grid cs-dashboard-header-toolbar">
            <div className="mbsc-row">
                <div className={width > 768 ? 'mbsc-col' : 'mbsc-col cs-dashboard-header-col-xs'} >
                    <div className="mbsc-row">
                        <div className="cs-dashboard-header-logo"></div>
                    </div>
                </div>
                <div className={width > 768 ? 'mbsc-col-auto' : 'mbsc-col-auto cs-dashboard-header-col-xs'}>
                    <div className="mbsc-row mbsc-justify-content-end">
                        {!user.candidate &&
                            <Button disabled={navigation.page === 'candidates'} color="primary" variant="flat" title={locale.dashboardHeader.c} className="mbsc-bold" onClick={() => Navigation({page: 'candidates', tab: 'search'})} >
                                <FontAwesomeIcon className={navigation.page === 'candidates' ? "cs-dashboard-header-button-icon-disabled" : "cs-dashboard-header-button-icon"} icon={faChalkboardUser} /> {width > 576 && <div className="cs-dashboard-header-text">{locale.dashboardHeader.c}</div>}
                            </Button>
                        }
                        {!user.candidate &&
                            <Button color="secondary" variant="flat" className="cs-dashboard-header-button-cursor" >
                                <FontAwesomeIcon className="cs-dashboard-header-button-icon-pipe" icon={faPipe} />
                            </Button>
                        }
                        {!user.candidate &&
                            <Button disabled={navigation.page === 'job'} color="primary" variant="flat" title={locale.dashboardHeader.d} className="mbsc-bold" onClick={() => Navigation({page: 'job', tab: 'published'})} >
                                <FontAwesomeIcon className={navigation.page === 'job' ? "cs-dashboard-header-button-icon-disabled" : "cs-dashboard-header-button-icon"} icon={faClipboardListCheck} /> {width > 576 && <div className="cs-dashboard-header-text">{locale.dashboardHeader.d}</div>}
                            </Button>
                        }
                        {user.options.superAdmin &&
                            <Button color="secondary" variant="flat" className="cs-dashboard-header-button-cursor" >
                                <FontAwesomeIcon className="cs-dashboard-header-button-icon-pipe" icon={faPipe} />
                            </Button>
                        }
                        {(user.candidate || (!user.candidate && user.options.superAdmin)) &&
                            <Button disabled={navigation.page === 'jobs'} color="primary" variant="flat" title={locale.dashboardHeader.a} className="mbsc-bold" onClick={() => Navigation({page: 'jobs', tab: 'search'})} >
                                <FontAwesomeIcon className={navigation.page === 'jobs' ? "cs-dashboard-header-button-icon-disabled" : "cs-dashboard-header-button-icon"} icon={faClipboardListCheck} /> {width > 576 && <div className="cs-dashboard-header-text">{locale.dashboardHeader.a}</div>}
                            </Button>
                        }
                        {(user.candidate || (!user.candidate && user.options.superAdmin)) &&
                            <Button color="secondary" variant="flat" className="cs-dashboard-header-button-cursor" >
                                <FontAwesomeIcon className="cs-dashboard-header-button-icon-pipe" icon={faPipe} />
                            </Button>
                        }
                        {(user.candidate || (!user.candidate && user.options.superAdmin)) &&
                            <Button disabled={navigation.page === 'schools'} color="primary" variant="flat" title={locale.dashboardHeader.b} className="mbsc-bold" onClick={() => Navigation({page: 'schools', tab: 'search'})} >
                                <FontAwesomeIcon className={navigation.page === 'schools' ? "cs-dashboard-header-button-icon-disabled" : "cs-dashboard-header-button-icon"} icon={faSchool} /> {width > 576 && <div className="cs-dashboard-header-text">{locale.dashboardHeader.b}</div>}
                            </Button>
                        }
                        <Button color="secondary" variant="flat" className="cs-dashboard-header-button-cursor" >
                            <FontAwesomeIcon className="cs-dashboard-header-button-icon-pipe" icon={faPipe} />
                        </Button>
                        {user.avatar ?
                            <div id="dashboardHeaderMenu" onClick={() => Dialog({view: 'dashboardHeaderMenu'})} style={{backgroundImage: "url('https://www.gravatar.com/avatar/" + user.avatar + "?d=robohash')"}} className="cs-dashboard-header-avatar" />
                            :
                            <div id="dashboardHeaderMenu" onClick={() => Dialog({view: 'dashboardHeaderMenu'})} style={{backgroundImage: "url('https://www.gravatar.com/avatar/" + (+ new Date()) + "?d=robohash')"}} className="cs-dashboard-header-avatar" />
                        }
                    </div>
                </div>
            </div>
            {dialog.view === 'dashboardHeaderHelp' &&
                <DashboardHeaderHelp
                    dialog={dialog}
                    Dialog={Dialog}
                    locale={locale}
                    user={user}
                />
            }
            {dialog.view === 'dashboardHeaderCancel' &&
                <DashboardHeaderCancel
                    dialog={dialog}
                    Dialog={Dialog}
                    locale={locale}
                    user={user}
                />
            }
            {dialog.view === 'dashboardHeaderMenu' &&
                <DashboardHeaderMenu
                    dialog={dialog}
                    Dialog={Dialog}
                    locale={locale}
                    user={user}
                    configuration={configuration}
                    setLocale={setLocale}
                    navigation={navigation}
                    Navigation={Navigation}
                    setUser={setUser}
                    token={token}
                    wss={wss}
                    setAccount={setAccount}
                />
            }
            {dialog.view === "dashboardHeaderMembership" &&
                <DashboardHeaderMembership
                    locale={locale}
                    Dialog={Dialog}
                    account={account}
                    setAccount={setAccount}
                    dialog={dialog}
                />
            }
            {dialog.view === 'dashboardHeaderDiscount' &&
                <DashboardHeaderDiscount
                    dialog={dialog}
                    Dialog={Dialog}
                    locale={locale}
                />
            }
        </div>
    );
}

export default DashboardHeader;

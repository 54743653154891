import React, {useCallback, useEffect, useState, useContext} from 'react';
import {Input, Button, SegmentedGroup, Segmented, toast, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMagnifyingGlass, faDeleteLeft} from "@fortawesome/pro-duotone-svg-icons";
import '../styles/schoolsSearch.css';
import Empty from '../utils/Empty';
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function SchoolsSearch({locale, setResults, user, text, setText, results, Navigation}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [search, setSearch] = useState(user.search.schools);
    const [found, setFound] = useState(0);
    const [autocomplete, setAutocomplete] = useState([]);

    const Autocomplete = () => {
        const message = {
            type: 'wss',
            path: 'accounts-search',
            action: 'get',
            data: {}
        };
        wsCall(message);
    }

    const Complete = () => {
        let completedItems = 0;
        const totalItems = 5;

        if (user.publish && user.firstName && user.lastName && user.mobile && user.gender && user.ethnicity && user.primaryCitizenship) {
            completedItems++;
        }

        if (user.bio && user.bio.length !== 0) {
            const text = user.bio.replace(/<[^>]+>/g, '');
            const trimmedText = text.trim();

            if (trimmedText.length > 150) {
                completedItems++;
            }
        }

        if ((user.teacher || user.leader) && user.job && user.school && user.division) {
            completedItems++;
        }

        if (user.documents && Array.isArray(user.documents)) {
            if (user.teacher && !user.leader && user.documents.some(item => item.tag === "resume")) {
                completedItems++;
            } else if (user.leader && user.documents.some(item => item.tag === "resume") && user.documents.some(item => item.tag === "philosophy")) {
                completedItems++;
            }
        }

        if (user.referrals && Array.isArray(user.referrals)) {
            const arrayTimestamp = user.referrals.filter(item =>
                item.hasOwnProperty('request') && item.request != null
            );
            if (arrayTimestamp.length >= 1) {
                completedItems++;
            }
        }

        const completionPercentage = (completedItems / totalItems) * 100;

        return Math.round(completionPercentage);
    }

    const Search = () => {
        if (!text || text.length < 2) {
            toast({message: locale.schoolsSearch.f, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            setResults([]);
            setFound(0);

            const message = {
                type: 'wss',
                path: 'search',
                action: 'get',
                data: {
                    search: text,
                    domain: 'school'
                }
            };
            wsCall(message);
        }
    }

    const SegmentedName = data => {
        setSearch(JSON.parse(data.target.value));
        const itemSearch = {...user.search};
        itemSearch.schools = JSON.parse(data.target.value);
        ButtonClear();

        const message = {
            type: 'wss',
            path: 'user',
            action: 'update',
            data: {
                email: user.email,
                search: itemSearch
            }
        };
        wsCall(message);
    }

    const InputSuggestion = data => {
        setText(data);

        if (search) {
            const itemValue = data;
            let arrayAutocomplete = autocomplete;

            if (itemValue && arrayAutocomplete.length !== 0) {
                arrayAutocomplete = arrayAutocomplete.filter(item =>
                    item.text.toLowerCase().includes(itemValue.toLowerCase())
                );
            }
            if (!itemValue){
                setResults([]);
            } else {
                setResults(arrayAutocomplete);
            }
        }
    };

    const ButtonClear = () => {
        setText('');
        setResults([]);
        setFound(0);
    }

    const GetSearch = useCallback(data => {
        if (data.results) {
            setResults(data.results);
            if (data.results.length === 0) {
                toast({message: locale.schoolsSearch.g, color: 'danger', duration: 3000, display: 'bottom'});
            }
        }
        if (data.found) {
            setFound(data.found);
        }
    }, [locale.schoolsSearch.g]);

    const GetAccountsSearch = useCallback(data => {
        if (data.autocomplete) {
            setAutocomplete(data.autocomplete);
        }
    }, []);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'accounts-search') {
            GetAccountsSearch(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetAccountsSearch, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'search') {
            GetSearch(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetSearch, setWsResponse]);

    useEffect(() => {
        if (Complete() === 100 ) {
            toast({message: locale.schoolsSearch.c, color: 'info', duration: 1000, display: 'bottom'});
            Autocomplete();
        }
    }, []);

    return (
        <>
            {Complete() !== 100 &&
                <div className="mbsc-padding">
                    <p className="mbsc-bold mbsc-txt-muted">{locale.schoolsSearch.a} {Complete()}{locale.schoolsSearch.b} <a href="#" onClick={() => Navigation({page: 'user', tab: 'information'})}>{locale.schoolsSearch.h}</a></p>
                </div>
            }
            {Complete() === 100 &&
                <Input
                    className="cs-schools-search-field-input"
                    id="dashboardSchoolsSearchAutocomplete"
                    inputStyle="underline"
                    placeholder={search ? locale.schoolsSearch.o : locale.schoolsSearch.n}
                    labelStyle="floating"
                    type="text"
                    name="search"
                    value={text}
                    onChange={ev => InputSuggestion(ev.target.value)}
                    onKeyPress={ev => {if (ev.key === 'Enter' && !search) {Search()}}}
                />
            }
            {Complete() === 100 &&
                <div className="mbsc-row cs-schools-search-footer">
                    <div className="mbsc-col-lg-4 mbsc-col-xl-3 cs-schools-search-footer-segmented">
                        <SegmentedGroup
                            cssClass="cs-schools-search-footer-menu"
                            name="schoolsSearch"
                            color="primary"
                            value={search}
                            onChange={SegmentedName}
                        >
                            <Segmented
                                value={false}
                            >
                                {locale.schoolsSearch.k}
                            </Segmented>
                            <Segmented
                                value={true}
                            >
                                {locale.schoolsSearch.j}
                            </Segmented>
                        </SegmentedGroup>
                    </div>
                    <div className="mbsc-col" />
                    <div className="mbsc-col-auto">
                        <div className="mbsc-row">
                            <Button disabled={!text} color={!text ? 'secondary' : 'primary'} variant="flat" className="mbsc-bold" onClick={() => ButtonClear()} >
                                <FontAwesomeIcon className="cs-schools-search-button-icon-send" icon={faDeleteLeft} /> {locale.schoolsSearch.d}
                            </Button>
                            {!search &&
                                <Button disabled={text.length === 0} color={(text.length === 0) ? 'secondary' : 'primary'} variant="flat" className="mbsc-bold" onClick={() => Search()} >
                                    <FontAwesomeIcon className="cs-schools-search-button-icon-send" icon={faMagnifyingGlass} /> {locale.schoolsSearch.e}
                                </Button>
                            }
                        </div>
                    </div>
                </div>
            }
            {Complete() === 100 && search && text.length !== 0 && results.length === 0 &&
                <div className="mbsc-padding">
                    <p className="mbsc-bold mbsc-txt-muted">{locale.schoolsSearch.p}</p>
                </div>
            }
        </>
    );
}

export default SchoolsSearch;

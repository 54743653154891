import React, {useState, useContext, useCallback, useEffect} from 'react';
import {Button, Checkbox, Input, Select, toast, confirm, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import dayjs from 'dayjs';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrashCan} from "@fortawesome/pro-duotone-svg-icons";
import '../styles/jobJob.css';
import Empty from '../utils/Empty';
import Menu from "../navigation/Menu";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function JobJob({locale, job, setJob, Navigation, navigation}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [publish, setPublish] = useState(job.publish);
    const [title, setTitle] = useState(job.title);
    const [type, setType] = useState(job.type);
    const [grade, setGrade] = useState(job.grade);
    const [role, setRole] = useState(job.role);
    const [toSave, setToSave] = useState(false);

    const ButtonDelete = () => {
        confirm({
            title: locale.jobJob.ac,
            message: locale.jobJob.ad,
            okText: locale.jobJob.ae,
            cancelText: locale.jobJob.af,
            callback: (res) => {
                if (res) {
                    const message = {
                        type: 'wss',
                        path: 'job',
                        action: 'delete',
                        data: {
                            id: job.id
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    const ItemSave = useCallback(() => {
        if (!title) {
            toast({message: locale.jobJob.t, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            const item = {...job};
            item.publish = publish;
            item.title = title;
            item.type = type;
            item.grade = grade;
            item.role = role;
            setJob(item);

            const message = {
                type: 'wss',
                path: 'job',
                action: 'update',
                data: {
                    id: job.id,
                    publish: publish,
                    title: title,
                    type: type,
                    grade: grade,
                    role: role
                }
            };
            wsCall(message);
        }
    }, [grade, job, locale.jobJob.t, publish, role, title, type]);

    const UpdateJob = useCallback(data => {
        if (data.toast) {
            toast({message: locale.jobJob.w, color: 'info', duration: 1000, display: 'bottom'});
        }
    }, [locale.jobJob.w]);

    const DeleteJob = useCallback(data => {
        if (data.toast) {
            toast({message: locale.jobJob.w, color: 'info', duration: 1000, display: 'bottom'});
            Menu(0, Navigation, navigation);
        }
    }, [locale.jobJob.w, Navigation, navigation]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'job') {
            DeleteJob(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeleteJob, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'job') {
            UpdateJob(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateJob, setWsResponse]);

    useEffect(() => {
        if (toSave) {
            ItemSave();
            setToSave(false);
        }
    }, [toSave, ItemSave]);

    useEffect(() => {
        Navigation({menu: locale.jobJob.z});
    }, []);

    return (
        <>
            <div className="mbsc-row cs-job-job-main">
                <div className="mbsc-col">
                    <Input inputStyle="outline" label={locale.jobJob.a} labelStyle="floating" type="text" name="title" value={title} onBlur={ItemSave} onChange={ev => setTitle(ev.target.value)} />
                    <Checkbox
                        cssClass="cs-job-job-checkbox mbsc-bold"
                        color="info"
                        position="start"
                        label={publish ? locale.jobJob.aa : locale.jobJob.b}
                        description={publish ? locale.jobJob.ab : locale.jobJob.c}
                        checked={publish}
                        onChange={(ev) => {setPublish(ev.target.checked); setToSave(true);}}
                    />
                    <Input disabled inputStyle="outline" label={locale.jobJob.r} labelStyle="floating" type="text" name="timestamp" value={dayjs(job.timestamp).format("MMMM D, YYYY")} />
                    <Select
                        animation="slide-down"
                        dropdown={false}
                        inputStyle="outline"
                        label={locale.jobJob.q}
                        labelStyle="floating"
                        rows={locale.jobJob.d.length}
                        display="anchored"
                        touchUi={false}
                        value={type}
                        data={locale.jobJob.d}
                        onChange={args => {setType(args.value); setToSave(true);}}
                    />
                    <Select
                        animation="slide-down"
                        dropdown={false}
                        inputStyle="outline"
                        label={locale.jobJob.i}
                        labelStyle="floating"
                        rows={locale.jobJob.x.length}
                        display="anchored"
                        touchUi={false}
                        value={grade}
                        data={locale.jobJob.x}
                        onChange={args => {setGrade(args.value); setToSave(true);}}
                    />
                    <Select
                        animation="slide-down"
                        dropdown={false}
                        inputStyle="outline"
                        label={locale.jobJob.n}
                        labelStyle="floating"
                        rows={locale.jobJob.y.length}
                        display="anchored"
                        touchUi={false}
                        value={role}
                        data={locale.jobJob.y}
                        onChange={args => {setRole(args.value); setToSave(true);}}
                    />
                </div>
            </div>
            <div className="mbsc-row cs-job-job-footer mbsc-justify-content-end">
                <Button color="primary" variant="flat" className="mbsc-bold" onClick={() => ButtonDelete()} >
                    <FontAwesomeIcon className="cs-job-job-icon-upload" icon={faTrashCan} /> {locale.jobJob.v}
                </Button>
            </div>
        </>
    );
}

export default JobJob;

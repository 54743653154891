import React, {useCallback, useContext, useEffect, useRef} from 'react';
import {Popup, Button, toast, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderMenu.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLanguage, faPowerOff, faQuestion, faSchool, faUser, faCreditCard, faUsers} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderMenu({Dialog, dialog, locale, user, configuration, setLocale, navigation, Navigation, setUser, token, wss, setAccount}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const menu = useRef([{user: true}, {school: true}, {users: true}, {membership: true}, {help: true}, {locale: true}, {logout: true}]);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ListItem = data => {
        let item = '';

        if (data.item.user) {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-menu-li">
                <Button color="primary" variant="flat" className="cs-dashboard-header-menu-icon mbsc-bold" disabled={navigation.page === 'user'} onClick={() => {Navigation({page: 'user', tab: 'information'}); DialogClose();}} >
                    <FontAwesomeIcon className={navigation.page === 'user' ? 'cs-dashboard-header-menu-icon-secondary-disabled' : 'cs-dashboard-header-menu-icon-secondary'} icon={faUser} /> {user.candidate ? locale.dashboardHeaderMenu.c : locale.dashboardHeaderMenu.a}
                </Button>
            </li>;
        } else if (data.item.school && !user.candidate) {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-menu-li">
                <Button color="primary" variant="flat" className="cs-dashboard-header-menu-icon mbsc-bold" disabled={navigation.page === 'school'} onClick={() => {Navigation({page: 'school', tab: 'diversity'}); DialogClose();}} >
                    <FontAwesomeIcon className={navigation.page === 'school' ? 'cs-dashboard-header-menu-icon-secondary-disabled' : 'cs-dashboard-header-menu-icon-secondary'} icon={faSchool} /> {locale.dashboardHeaderMenu.g}
                </Button>
            </li>;
        } else if (data.item.users && !user.candidate) {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-menu-li">
                <Button color="primary" variant="flat" className="cs-dashboard-header-menu-icon mbsc-bold" disabled={navigation.page === 'users'} onClick={() => {Navigation({page: 'users', tab: 'users'}); DialogClose();}} >
                    <FontAwesomeIcon className={navigation.page === 'users' ? 'cs-dashboard-header-menu-icon-secondary-disabled' : 'cs-dashboard-header-menu-icon-secondary'} icon={faUsers} /> {locale.dashboardHeaderMenu.k}
                </Button>
            </li>;
        } else if (data.item.membership && !user.candidate && user.options.owner && !user.options.superAdmin) {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-menu-li">
                <Button color="primary" variant="flat" className="cs-dashboard-header-menu-icon mbsc-bold" onClick={() => Dialog({view: 'dashboardHeaderMembership'})} >
                    <FontAwesomeIcon className="cs-dashboard-header-menu-icon-secondary" icon={faCreditCard} /> {locale.dashboardHeaderMenu.h}
                </Button>
            </li>;
        } else if (data.item.help && !user.options.superAdmin) {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-menu-li">
                <Button color="primary" variant="flat" className="cs-dashboard-header-menu-icon mbsc-bold" onClick={() => Dialog({view: 'dashboardHeaderHelp'})} >
                    <FontAwesomeIcon className="cs-dashboard-header-menu-icon-secondary" icon={faQuestion} /> {locale.dashboardHeaderMenu.b}
                </Button>
            </li>;
        } else if (data.item.locale) {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-menu-li">
                <Button color="primary" variant="flat" className="cs-dashboard-header-menu-icon mbsc-bold" onClick={() => {user.locale === 'en' ? ButtonLocale('es') : ButtonLocale('en')}} >
                    <FontAwesomeIcon className="cs-dashboard-header-menu-icon-secondary" icon={faLanguage} /> {user.locale === 'en' ? locale.dashboardHeaderMenu.f : locale.dashboardHeaderMenu.e}
                </Button>
            </li>;
        } else if (data.item.logout) {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-menu-li">
                <Button color="primary" variant="flat" className="cs-dashboard-header-menu-icon mbsc-bold" onClick={() => ButtonLogout()} >
                    <FontAwesomeIcon className="cs-dashboard-header-menu-icon-danger" icon={faPowerOff} /> {locale.dashboardHeaderMenu.d}
                </Button>
            </li>;
        }

        return item;
    }

    const ButtonLogout = useCallback(() => {
        toast({message: locale.dashboardHeaderMenu.i, color: 'info', duration: 1000, display: 'bottom'});
        window.localStorage.removeItem('token');
        token.current = '';
        wss.current = '';
        setUser({});
        setAccount({});
        Navigation({view: 'login'});
    }, [locale.dashboardHeaderMenu.i, Navigation]);

    const ButtonLocale = data => {
        DialogClose();

        if (data === 'es') {
            setLocale(configuration.locale.es);
            window.localStorage.locale = 'es';
        } else if (data === 'en') {
            setLocale(configuration.locale.en);
            window.localStorage.locale = 'en';
        }

        const item = {...user};
        item.locale = data;
        setUser(item);

        const message = {
            type: 'wss',
            path: 'user-locale',
            action: 'update',
            data: {
                locale: data
            }
        };
        wsCall(message);
    }

    const UpdateUserLocale = useCallback(data => {
        if (data.toast) {
            toast({message: locale.dashboardHeaderMenu.j, color: 'info', duration: 1000, display: 'bottom'});
        }
    }, [locale.dashboardHeaderMenu.j]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'user-locale') {
            UpdateUserLocale(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateUserLocale, setWsResponse]);

    return (
        <Popup className="cs-dashboard-header-menu-popup" display="anchored" anchor={document.getElementById('dashboardHeaderMenu')} buttons={[]} isOpen={dialog.view === 'dashboardHeaderMenu'} onClose={DialogClose} >
            <Listview
                theme="ios"
                themeVariant="light"
                select="single"
                itemType={ListItem}
                data={menu.current}
            />
        </Popup>
    );
}

export default DashboardHeaderMenu;

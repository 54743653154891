import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Popup, setOptions, Textarea, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderDiscount.css';
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderDiscount({Dialog, dialog, locale}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [text, setText] = useState('');

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonDiscount = () => {
        if (!text) {
            toast({message: locale.dashboardHeaderDiscount.a, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            const message = {
                type: 'wss',
                path: 'coupon',
                action: 'put',
                data: {
                    text: text
                }
            };
            wsCall(message);
        }
    }

    const PutCoupon = useCallback(data => {
        if (data.toast) {
            toast({message: locale.dashboardHeaderDiscount.b, color: 'info', duration: 1000, display: 'bottom'});
            DialogClose()
        }
    }, [DialogClose, locale.dashboardHeaderDiscount.b]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'coupon') {
            PutCoupon(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutCoupon, setWsResponse]);

    return (
        <Popup className="cs-dashboard-header-discount-popup" width={600} closeOnOverlayClick={false} closeOnEsc={false} display="center" scrollLock={false} headerText={locale.dashboardHeaderDiscount.c} buttons={[{text: locale.dashboardHeaderDiscount.f, cssClass: 'cs-dashboard-header-discount-cancel', handler: () => DialogClose()},{text: locale.dashboardHeaderDiscount.e, cssClass: 'cs-dashboard-header-discount-save', handler: () => ButtonDiscount()}]} isOpen={dialog.view === 'dashboardHeaderDiscount'} onClose={DialogClose}>
            <div className="cs-dashboard-header-discount-helper">
                <p className="mbsc-txt-muted">
                    {locale.dashboardHeaderDiscount.g}
                </p>
            </div>
            <div className="cs-dashboard-header-discount-textarea">
                <Textarea inputStyle="underline" labelStyle="floating" label={locale.dashboardHeaderDiscount.d} value={text} onChange={(ev) => setText(ev.target.value)} />
            </div>
        </Popup>
    );
}

export default DashboardHeaderDiscount;

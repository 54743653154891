import React, {useState, useContext} from 'react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/candidateNotesShared.css';
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {NetworkContext} from "../utils/NetworkContext";

function CandidateNotesShared({locale, candidate, candidateData, setDraft}) {
    const {wsCall} = useContext(NetworkContext);
    const [text, setText] = useState(candidateData.notesShared.text ? candidateData.notesShared.text : '');
    const [notes, setNotes] = useState(candidateData.notesShared);

    const EditorChange = data => {
        setText(data);

        if (data && data.length % 10 === 0) {
            const item = {...notes};
            item.text = data;
            setNotes(item);

            const message = {
                type: 'wss',
                path: 'user-notes',
                action: 'put',
                data: {
                    private: candidateData.notes,
                    shared: item,
                    candidate: candidate.email,
                    avatar: candidate.avatar,
                    firstName: candidate.firstName,
                    lastName: candidate.lastName
                }
            };
            wsCall(message);
        }
    }

    const EditorBlur = data => {
        const item = {...notes};
        item.text = text;
        setNotes(item);

        const message = {
            type: 'wss',
            path: 'user-notes',
            action: 'put',
            data: {
                private: candidateData.notes,
                shared: item,
                candidate: candidate.email,
                avatar: candidate.avatar,
                firstName: candidate.firstName,
                lastName: candidate.lastName
            }
        };
        wsCall(message);
    }

    return (
        <>
            <div className="mbsc-row cs-candidate-notes-shared-main">
                <div style={{backgroundImage: "url(https://www.gravatar.com/avatar/" + candidate.avatar + "?d=robohash)"}} className="cs-candidate-notes-shared-avatar" />
                <div>
                    <div>
                        <h4 className="cs-candidate-notes-shared-header-title">
                            {candidate.firstName} {candidate.lastName}
                        </h4>
                    </div>
                    <div>
                        {candidate.email && candidate.email}
                        {candidate.country && ' | '}
                        {candidate.country && candidate.country}
                    </div>
                </div>
            </div>
            <div className="cs-candidate-notes-shared-col-ck">
                <CKEditor
                    id="editor-focus"
                    config={{toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'undo', 'redo']}}
                    editor={ClassicEditor}
                    data={text}
                    onChange={(event, editor) => EditorChange(editor.getData())}
                    onBlur={() => {EditorBlur(); setDraft(false);}}
                />
            </div>
            <div className="mbsc-row cs-candidate-notes-shared-footer">
                <div className="mbsc-col cs-candidate-notes-shared-footer-actions">
                    <div className="mbsc-txt-muted mbsc-italic">
                        {locale.candidatesNotesShared.b}
                    </div>
                </div>
                <div className="mbsc-col-auto cs-candidate-notes-shared-footer-note" />
            </div>
        </>
    );
}

export default CandidateNotesShared;

import React, {useContext, useState} from 'react';
import {Checkbox, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/userOptions.css';
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function UserOptions({locale, user, email}) {
    const {wsCall} = useContext(NetworkContext);
    const [admin, setAdmin] = useState(user.options.admin);
    const [owner, setOwner] = useState(user.options.owner);
    const [superAdmin, setSuperAdmin] = useState(user.options.superAdmin);
    const [notify, setNotify] = useState(user.options.notify);

    const CheckboxUpdate = data => {
        if (data.superAdmin === true || data.superAdmin === false) {
            setSuperAdmin(data.superAdmin);
        } else if (data.admin === true || data.admin === false) {
            setAdmin(data.admin);
        } else if (data.owner === true || data.owner === false) {
            setOwner(data.owner);
        } else if (data.notify === true || data.notify === false) {
            setNotify(data.notify);
        }

        const message = {
            type: 'wss',
            path: 'user',
            action: 'update',
            data: {
                email: email,
                options: {
                    admin: (data.admin === true || data.admin === false) ? data.admin : admin,
                    owner: (data.owner === true || data.owner === false) ? data.owner : owner,
                    superAdmin: (data.superAdmin === true || data.superAdmin === false) ? data.superAdmin : superAdmin,
                    notify: (data.notify === true || data.notify === false) ? data.notify : notify
                }
            }
        };
        wsCall(message);
    }

    return (
        <>
            <div className="mbsc-row cs-user-options-main">
                <div className="mbsc-col">
                    {superAdmin &&
                        <Checkbox
                            cssClass="cs-user-options-checkbox mbsc-bold"
                            color="info"
                            position="start"
                            label={locale.userOptions.i}
                            description={locale.userOptions.a}
                            checked={superAdmin}
                            onChange={(ev) => CheckboxUpdate({superAdmin: ev.target.checked})}
                        />
                    }
                    <Checkbox
                        disabled={!admin}
                        cssClass="cs-user-options-checkbox mbsc-bold"
                        color="info"
                        position="start"
                        label={locale.userOptions.b}
                        description={locale.userOptions.c}
                        checked={admin}
                        onChange={(ev) => CheckboxUpdate({admin: ev.target.checked})}
                    />
                    <Checkbox
                        disabled={!owner}
                        cssClass="cs-user-options-checkbox mbsc-bold"
                        color="info"
                        position="start"
                        label={locale.userOptions.d}
                        description={locale.userOptions.e}
                        checked={owner}
                        onChange={(ev) => CheckboxUpdate({owner: ev.target.checked})}
                    />
                    <Checkbox
                        disabled={!owner}
                        cssClass="cs-user-options-checkbox mbsc-bold"
                        color="info"
                        position="start"
                        label={locale.userOptions.f}
                        description={locale.userOptions.g}
                        checked={notify}
                        onChange={(ev) => CheckboxUpdate({notify: ev.target.checked})}
                    />
                </div>
            </div>
        </>
    );
}

export default UserOptions;

import React, {useEffect, useState} from 'react';
import {SegmentedGroup, Segmented, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/job.css';
import '../styles/ckeditor.css';
import Empty from "../utils/Empty";
import Menu from "../navigation/Menu";
import Item from "../navigation/Item";
import Notes from "../shared/Notes";
import JobPublished from "../blocks/JobPublished";
import JobDrafts from "../blocks/JobDrafts";
import JobJob from "../blocks/JobJob";
import JobOverview from "../blocks/JobOverview";
import JobRequirements from "../blocks/JobRequirements";
import JobResponsibilities from "../blocks/JobResponsibilities";
import JobContact from "../blocks/JobContact";
import JobNew from "../blocks/JobNew";
import JobCandidates from "../blocks/JobCandidates";
import JobNotifications from "../blocks/JobNotifications";
import Candidate from "../shared/Candidate";
import CandidateNotes from "../shared/CandidateNotes";
import CandidateNotesShared from "../shared/CandidateNotesShared";
import dayjs from "dayjs";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function Job({width, locale, navigation, Navigation, dialog, Dialog, user, account, configuration}) {
    const [job, setJob] = useState({});
    const [candidate, setCandidate] = useState({});

    const Division = data => {
        let item = ''
        locale.job.d.some(itemDivision => {
            if (itemDivision.value === data) {
                item = itemDivision.text;
                return true;
            }
            return false;
        });
        return item;
    }

    useEffect(() => {
        Navigation({menu: locale.job.i});
    }, []);

  return (
      <div className="mbsc-grid cs-job-main">
          <div className={width > 768 ? "mbsc-row cs-job-container" : "mbsc-row cs-job-container-xs"}>
              <div className="mbsc-col cs-job-title">
                  <p className={width > 768 ? "mbsc-txt-l" : "mbsc-txt-m"}>
                      {locale.job.b}
                  </p>
              </div>
          </div>
          <div className="mbsc-row">
              {width > 768 &&
                  <div className="mbsc-col-12 mbsc-col-md-3 mbsc-col-sm-12 cs-job-left">
                      {navigation.menu && navigation.menu.length !== 0 &&
                          <Listview
                              theme="ios"
                              themeVariant="light"
                              select="single"
                              itemType={event => Item(event, navigation, null)}
                              data={navigation.menu}
                              onItemTap={event => Menu(event.index, Navigation, navigation)}
                          />
                      }
                      <Notes
                          locale={locale}
                          dialog={dialog}
                          Dialog={Dialog}
                          user={user}
                      />
                  </div>
              }
              <div className="mbsc-col-12 mbsc-col-md-9 mbsc-col-sm-12 cs-job-right">
                  {(navigation.tab === 'job' || navigation.tab === 'overview' || navigation.tab === 'requirements' || navigation.tab === 'responsibilities' || navigation.tab === 'contact' || navigation.tab === 'notifications') &&
                      <div className="mbsc-row cs-job-section">
                          {(!Empty(account.logo) && account.logo.link) ?
                              <div style={{backgroundImage: "url(" + account.logo.link + ")"}} className="cs-job-avatar" />
                              :
                              <div style={{backgroundImage: "url('https://www.gravatar.com/avatar/" + (+ new Date()) + "?d=identicon')"}} className="cs-job-avatar" />
                          }
                          <div>
                              <div>
                                  <h4 className="cs-job-header-title">{job.title && job.title}</h4>
                              </div>
                              <span>
                                  {Division(job.grade)} | {locale.job.j}: {dayjs(job.timestamp).format("MMMM D, YYYY")}
                              </span>
                          </div>
                      </div>
                  }
                  {navigation.menu && navigation.menu.length !== 0 && width < 768 &&
                      <div className="mbsc-row cs-job-section">
                          <div className="mbsc-col">
                              <SegmentedGroup className="cs-job-nav" name="jobs" color="primary" value={navigation.tab} onChange={ev => Menu(ev, Navigation, navigation)}>
                                  {navigation.menu.map((item) => {
                                      return (
                                          <Segmented value={item.name} >
                                              {item.label}
                                          </Segmented>
                                      );
                                  })}
                              </SegmentedGroup>
                          </div>
                      </div>
                  }
                  {navigation.tab === 'published' &&
                      <JobPublished
                          locale={locale}
                          user={user}
                          setJob={setJob}
                          Navigation={Navigation}
                          account={account}
                          Division={Division}
                          width={width}
                      />
                  }
                  {navigation.tab === 'drafts' &&
                      <JobDrafts
                          locale={locale}
                          user={user}
                          setJob={setJob}
                          Navigation={Navigation}
                          account={account}
                          Division={Division}
                      />
                  }
                  {navigation.tab === 'job' && !Empty(job) &&
                      <JobJob
                          locale={locale}
                          job={job}
                          setJob={setJob}
                          Navigation={Navigation}
                          navigation={navigation}
                      />
                  }
                  {navigation.tab === 'overview' && !Empty(job) &&
                      <JobOverview
                          locale={locale}
                          job={job}
                          setJob={setJob}
                      />
                  }
                  {navigation.tab === 'requirements' && !Empty(job) &&
                      <JobRequirements
                          locale={locale}
                          job={job}
                          setJob={setJob}
                      />
                  }
                  {navigation.tab === 'responsibilities' && !Empty(job) &&
                      <JobResponsibilities
                          locale={locale}
                          job={job}
                          setJob={setJob}
                      />
                  }
                  {navigation.tab === 'contact' && !Empty(job) &&
                      <JobContact
                          locale={locale}
                          job={job}
                          setJob={setJob}
                      />
                  }
                  {navigation.tab === 'new' &&
                      <JobNew
                          locale={locale}
                          Navigation={Navigation}
                          setJob={setJob}
                      />
                  }
                  {navigation.tab === 'candidates' &&
                      <JobCandidates
                          locale={locale}
                          job={job}
                          setCandidate={setCandidate}
                          Navigation={Navigation}
                          width={width}
                      />
                  }
                  {navigation.tab === 'notifications' && !Empty(job) &&
                      <JobNotifications
                          locale={locale}
                          job={job}
                      />
                  }
                  {navigation.tab === 'candidate' && !Empty(candidate) &&
                      <Candidate
                          locale={locale}
                          candidate={candidate.candidate}
                          candidateData={candidate}
                          configuration={configuration}
                          width={width}
                          Dialog={Dialog}
                          dialog={dialog}
                          navigation={navigation}
                          Navigation={Navigation}
                      />
                  }
                  {navigation.tab === 'candidateNotes' && !Empty(candidate) &&
                      <CandidateNotes
                          locale={locale}
                          candidate={candidate.candidate}
                          candidateData={candidate}
                      />
                  }
                  {navigation.tab === 'shared' && !Empty(candidate) &&
                      <CandidateNotesShared
                          locale={locale}
                          candidate={candidate.candidate}
                          candidateData={candidate}
                      />
                  }
              </div>
          </div>
      </div>
  );
}

export default Job;

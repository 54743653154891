import React, {useCallback, useState, useContext} from 'react';
import {Popup, Select, Textarea, toast, confirm, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import Empty from "../utils/Empty";
import '../styles/notesNote.css';
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function NotesNote({locale, Dialog, dialog, note, notes, setNotes, user}) {
    const {wsCall} = useContext(NetworkContext);
    const [text, setText] = useState(note.text ? note.text : '');
    const [type, setType] = useState(note.type ? note.type : 'primary');

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonSave = () => {
        console.log(text);
        if (!text) {
            toast({message: locale.notesNote.l, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            let array = notes;
            if (!Empty(note)) {
                array = array.map(function(item){
                    if (note.text === item.text) {
                        item.text = text;
                        item.type = type;
                    }
                    return item;
                })
            } else {
                const item = {
                    text: text,
                    type: type
                }
                array.push(item);
            }
            setNotes(array);
            const message = {
                type: 'wss',
                path: 'user',
                action: 'update',
                data: {
                    email: user.email,
                    notes: notes
                }
            };
            wsCall(message);
        }
    }

    const ButtonDelete = () => {
        confirm({
            title: locale.notesNote.n,
            message: locale.notesNote.o,
            okText: locale.notesNote.p,
            cancelText: locale.notesNote.q,
            callback: (res) => {
                if (res) {
                    let array = notes;
                    array = array.filter(function(item){
                        return item.text !== text;
                    })
                    setNotes(array);

                    const message = {
                        type: 'wss',
                        path: 'user',
                        action: 'update',
                        data: {
                            email: user.email,
                            notes: array
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    return (
        <Popup className="cs-notes-note-popup" width={600} closeOnOverlayClick={false} closeOnEsc={false} display="center" headerText={!Empty(note) ? locale.notesNote.a : locale.notesNote.u} buttons={!Empty(note) ? [{text: locale.notesNote.q, cssClass: 'cs-notes-note-delete', handler: 'cancel'}, {text: locale.notesNote.b, cssClass: 'cs-notes-note-delete', handler: () => ButtonDelete()}, {text: locale.notesNote.m, cssClass: 'cs-notes-note-save', handler: () => ButtonSave()}] : [{text: locale.notesNote.q, cssClass: 'cs-notes-note-delete', handler: 'cancel'}, {text: locale.notesNote.m, cssClass: 'cs-notes-note-save', handler: () => ButtonSave()}]} isOpen={dialog.view === 'note'} onClose={DialogClose}>
            {Empty(note) &&
                <div className="cs-notes-note-helper">
                    <p className="mbsc-txt-muted">
                        {locale.notesNote.v}
                    </p>
                </div>
            }
            <div className="cs-notes-note-textarea">
                <Textarea inputStyle="underline" labelStyle="floating" value={text} onChange={(ev) => setText(ev.target.value)} />
            </div>
            {Empty(note) &&
                <div className="cs-notes-note-helper">
                    <p className="mbsc-txt-muted">
                        {locale.notesNote.x}
                    </p>
                </div>
            }
            <Select
                animation="slide-down"
                dropdown={false}
                inputStyle="underline"
                label={locale.notesNote.c}
                labelStyle="floating"
                rows={locale.notesNote.t.length}
                display="anchored"
                touchUi={false}
                value={type}
                data={locale.notesNote.t}
                onChange={args => setType(args.value)}
            />
            {Empty(note) &&
                <div className="cs-notes-note-helper">
                    <p className="mbsc-txt-muted">
                        {locale.notesNote.y}
                    </p>
                </div>
            }
        </Popup>
    );
}

export default NotesNote;
